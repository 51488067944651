import React, { useEffect, useState } from 'react'
import ThemeButton from '../qed-theme-module/ThemeButton';
import { IMAGE_TYPES } from '../utility-files/default-values/DefaultValues';
import CustomInput from '../custom-input-fields/CustomInput';
import Image from '../pages/common-module/Image';
import { alertMessages, alertTypes, users_roles } from '../utility-files/helper-function/HelperFunction';
import { isStringValue } from '../utility-files/data-util/DataHandling';
import * as global from '../constants/global'
import { getRequestForApi } from '../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../utility-files/api-util/HttpRequest';
import CustomLoader from '../custom-ui/CustomLoader';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';

function AssignRole({ showModal, setShowAssignRoleModel, onSuccess }) {
    const themeData = useSelector((state) => state.reduxReducer.themeData);

    const elementStyle = {
        fontFamily: isStringValue(themeData?.fontName),
        color: isStringValue(themeData?.textColor)
    };

    const alert = useAlert();
    const [roleType, setRoleType] = useState(null);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (showModal) {
            setRoleType(showModal);
        } else {
            setRoleType(null);
        }
    }, [showModal]);

    const updateUserRole = async () => {
        setPending(true);
        let variables = {}, request;
        variables.userId = isStringValue(roleType['id']);
        variables.role = isStringValue(roleType['role']);
        request = getRequestForApi(global.UPDATE_USER_ROLE, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show(alertMessages.USER_ROLE_UPDATE_SUCCESS, { type: alertTypes.SUCCESS });
                setShowAssignRoleModel(null);
                onSuccess();
            };
            setPending(false);
        }).catch((err) => {
            // alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    };

    return (
        <>
            {pending && <CustomLoader />}
            <div className={`modal ${showModal ? 'show pageoverlay-model' : 'fade'}`} id="AddUpdateTheme" tabIndex="-1" aria-labelledby="addItemModel" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className='modal-dialog modal-dialog-scrollable'>
                    <div className='modal-content'>
                        <div className="modal-header">
                            <div className="modal-head d-flex gap-2">
                                <Image src={IMAGE_TYPES.CATEGORY_IMAGE} alt />
                                <h5 style={elementStyle} className="modal-title" id="exampleModalLabel">Change Role</h5>
                            </div>
                            <div className="close">
                                <button type="button" className="small btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => setShowAssignRoleModel(null)}
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="modal-input">
                                <label htmlFor="theme name" style={elementStyle}>User Name</label>
                                <div className="input-box d-flex">
                                    <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                    <CustomInput
                                        fieldType={"text"}
                                        classType={"form-control"}
                                        setFieldData={setRoleType}
                                        filedData={showModal}
                                        fieldId={'#AssignRoledisplayName'}
                                        keyName={'displayName'}
                                        isReadOnly={'true'}
                                        style={elementStyle}
                                    />
                                </div>
                            </div>
                            <div className="modal-input">
                                <label htmlFor="theme name" style={elementStyle}>Assign Role<span className="required">*</span></label>
                                <div className="input-box d-flex">
                                    <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                    <CustomInput
                                        style={elementStyle}
                                        fieldType={"select"}
                                        fieldId={'#trole'}
                                        classType={"form-select select-content"}
                                        setFieldData={setRoleType}
                                        filedData={roleType}
                                        keyName={'role'}
                                        selectList={users_roles}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex">
                            <ThemeButton type="button" className={`btn btn-primary ${showModal?.['role'] === roleType?.['role'] ? 'disable-button' : ''}`} onClick={() => updateUserRole()}>Assign Role</ThemeButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssignRole
