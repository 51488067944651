import React, { useEffect, useState, lazy, Suspense } from 'react'
import { IMAGE_TYPES } from '../utility-files/default-values/DefaultValues'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as HelperFunction from '../utility-files/helper-function/HelperFunction'
import { getStorageData, storageKey, storageType } from '../utility-files/storage-util/StorageHandling';
import { isArrayValue, isBooleanValue, isStringValue } from '../utility-files/data-util/DataHandling';
import { getRequestForApi } from '../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../utility-files/api-util/HttpRequest';
import * as global from '../constants/global'
import { ItemDetailsState, userRefetchState } from '../global-states/GlobalStates';
import { useHookstate } from '@hookstate/core';
import CustomLoader from '../custom-ui/CustomLoader';
import Image from '../pages/common-module/Image';
import ThemeAnchorLink from '../qed-theme-module/ThemeAnchorLink';
import HeaderNavLinks from './HeaderNavLinks';
import { FAQsIcon, FAQsSelectedIcon, ItemDetailsArrowIcon, NotificationIcon } from '../qed-theme-module/qed-logos/Logos';
import { useDispatch, useSelector } from 'react-redux';
import { currentTheme, userData, userLocation, walletData } from '../redux/reduxSlice';
const SideNavToggle = lazy(() => import('../custom-popup-model/SideNavToggle'));
const Notification = lazy(() => import('../custom-popup-model/Notification'));

function Header() {
    const params = useParams();
    const location = useLocation();
    const { item_Details } = useHookstate(ItemDetailsState);
    const { user_refetch } = useHookstate(userRefetchState);
    const isLoggedIn = getStorageData(storageType, storageKey.TOKEN) ? getStorageData(storageType, storageKey.TOKEN) : getStorageData(!storageType, storageKey.TOKEN);
    const loginPage = location?.pathname === HelperFunction.path.LOGIN;
    const aboutUs = location?.pathname === HelperFunction.path.ABOUT_US;
    const faqPage = location?.pathname === HelperFunction.path.FAQ;
    const historyPage = location?.pathname === HelperFunction.path.HISTORY;
    const welcomePage = location?.pathname === HelperFunction.path.WELCOME;
    const successPage = location?.pathname === HelperFunction.path.INVITE_ACCEPT_SUCCESS;
    const AcceptInvite = location?.pathname.includes(HelperFunction.path.ACCEPT_INVITE) || location?.pathname?.includes(HelperFunction.path.ACCEPT_USER_INVITE);
    const DisableNav = location?.pathname?.includes(HelperFunction.path.ACCEPT_INVITE) || location?.pathname?.includes(HelperFunction.path.ACCEPT_USER_INVITE) || location?.pathname === HelperFunction.path.INVITE_ACCEPT_SUCCESS;
    const paymentsPage = location?.pathname === HelperFunction.path.CANCEL || location?.pathname === HelperFunction.path.SUCCESS || location?.pathname === HelperFunction.path.HISTORY;
    const [pending, setPending] = useState(false);
    const [notificationData, setNotificationData] = useState(null);
    const UAT_SERVER = window.location.origin === HelperFunction.UAT_URL;
    const dispatch = useDispatch();
    const user_data = useSelector((state) => state.reduxReducer);
    const userDetails = user_data.userData;
    const themeData = user_data.themeData;

    const divCustomTheme = {
        background: isStringValue(themeData.backgroundColor),
        fontFamily: isStringValue(themeData.fontName),
    };

    const contentBackground = {
        background: isStringValue(themeData.contentBackgroundColor),
        fontFamily: isStringValue(themeData.fontName)
    };

    const anchorLinkTheme = {
        color: isStringValue(themeData?.linkColor),
        fontFamily: isStringValue(themeData?.fontName)
    };

    const headerStyle = {
        color: isStringValue(themeData.linkColor) + '80',
        fontFamily: isStringValue(themeData.fontName)
    };

    const COMPANY_LOGO = isStringValue(userDetails?.['imageUrl']) || IMAGE_TYPES.LOGO_IMAGE;
    const PROFILE_LOGO = isStringValue(userDetails?.['profileImageUrl']) || IMAGE_TYPES.SKULL;

    if (isLoggedIn && themeData) {
        document.body.style.background = isStringValue(divCustomTheme.background);
        document.body.style.fontFamily = isStringValue(divCustomTheme.fontFamily);
    };

    useEffect(() => {
        getUserLocation();
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            getUserDetails();
            readCurrentTheme();
            getUserWallet();
        };
    }, [location?.pathname, user_refetch.get()]);

    const getUserLocation = async () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let coords = {};
                if (position.coords.latitude && position.coords.longitude) {
                    coords.latitude = position.coords.latitude;
                    coords.longitude = position.coords.longitude;
                };
                if (coords.latitude && coords.longitude) {
                    dispatch(userLocation(coords));
                }
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    };

    const getUserDetails = async () => {
        setPending(true);
        let variables = undefined;
        let request = getRequestForApi(global.GET_USER_DETAILS, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                dispatch(userData(response?.data));
            };
            user_refetch.set(false);
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
        notificationDetails();
    };

    const notificationDetails = async () => {
        setPending(true);
        let variable = {};
        let request = getRequestForApi(global.NOTIFICATION_DATA, variable, methodType.POST);
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                let data = response?.data?.response?.data;
                setNotificationData(data);
            }
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        })
    };

    const readCurrentTheme = async () => {
        setPending(true);
        let request = getRequestForApi(global.READ_CURRENT_THEME, undefined, methodType.POST)
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                if (response?.data?.error === 201) {
                    const data = response?.data?.response?.data;
                    dispatch(currentTheme(data));
                };
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const getUserWallet = async () => {
        setPending(true);
        let request = getRequestForApi(global.GET_USER_WALLET, undefined, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data;
                dispatch(walletData(responseData));
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    const markNotificationAsRead = async () => {
        let variable = {};
        let request = getRequestForApi(global.MARK_ALL_READ, variable, methodType.POST);
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                notificationDetails();
                getUserDetails();
            };
        }).catch((err) => {
            console.log("err", err)
        });
    };

    return (
        <>
            {pending && <CustomLoader />}
            <div className="header">
                <header id="headerCntr" style={contentBackground}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="logo">
                                    {DisableNav ? <Image src={COMPANY_LOGO} className="logo" alt="" /> :
                                        <Link to={isLoggedIn ? HelperFunction.path.HOME : HelperFunction.path.LOGIN}>
                                            <Image src={COMPANY_LOGO} className="logo" alt="" />
                                            {UAT_SERVER && <Image src={IMAGE_TYPES.UAT} alt="msg" height='26px' width='45px' />}
                                        </Link>}
                                </div>
                                {/* {params && params?.id && !location?.pathname.includes('/mfa/') && <nav className="breadcrumbs d-none d-md-block">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <ThemeAnchorLink to="#" onClick={() => window.history.go(-1)}>
                                                <ItemDetailsArrowIcon fillColor={isStringValue(headerStyle?.color)} />
                                                {params && params?.id && decryptData(params?.id) === HelperFunction.path.ITEMS ? 'Items' : 'Home'}
                                            </ThemeAnchorLink>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">{isStringValue(item_Details.get()?.['name'])}</li>
                                    </ol>
                                </nav>} */}
                                {faqPage && <nav className="breadcrumbs d-none d-md-block" onClick={() => window.history.go(-1)}>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <ThemeAnchorLink to="#">
                                                <ItemDetailsArrowIcon fillColor={isStringValue(headerStyle?.color)} />
                                                FAQs
                                            </ThemeAnchorLink>
                                        </li>
                                    </ol>
                                </nav>}

                                {historyPage && <nav className="breadcrumbs d-none d-md-block" onClick={() => window.history.go(-1)}>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <ThemeAnchorLink to="#">
                                                <ItemDetailsArrowIcon fillColor={isStringValue(headerStyle?.color)} />
                                                Transaction History
                                            </ThemeAnchorLink>
                                        </li>
                                    </ol>
                                </nav>}

                                {isLoggedIn && !faqPage && !paymentsPage && !successPage ? <HeaderNavLinks userDetails={userDetails} /> : <></>}

                                <div className="headericon headericon-btns">
                                    {isLoggedIn && !successPage &&
                                        <ul>
                                            <li>
                                                <Link to={HelperFunction.path.FAQ}>
                                                    {/* <Image src={!faqPage ? IMAGE_TYPES.MESSAGE_LOGO : IMAGE_TYPES.MESSAGE_SELECTED_LOGO} alt="msg" /> */}
                                                    {!faqPage ? <FAQsIcon fillColor={isStringValue(anchorLinkTheme?.color)} /> : <FAQsSelectedIcon fillColor={isStringValue(anchorLinkTheme?.color)} />}
                                                </Link>
                                            </li>
                                            <li onClick={() => { if (isBooleanValue(userDetails?.isUnReadNotificationAvailable)) { markNotificationAsRead(); } }}>
                                                <a data-bs-toggle="modal" data-bs-target="#NotificationModal" href="#">
                                                    {/* <Image src={isBooleanValue(userDetails?.isUnReadNotificationAvailable) ? IMAGE_TYPES.NOTIFICATIONS_NEW : IMAGE_TYPES.NOTIFICATION_IMAGE} alt="notification" /> */}
                                                    <NotificationIcon fillColor={isStringValue(anchorLinkTheme?.color)} newNotification={isBooleanValue(userDetails?.isUnReadNotificationAvailable)} />
                                                </a>
                                            </li>
                                            <li data-bs-toggle="modal" data-bs-target=".bd-example-modal-sm" onClick={() => getUserDetails()}>
                                                {/* <a href="#"> */}
                                                <Image src={PROFILE_LOGO} className={'pointer'} height='25px' width='25px' alt="profile1" />
                                                {/* </a> */}
                                            </li>
                                        </ul>}
                                    {!welcomePage && !AcceptInvite && !successPage && <div className="nav-content d-flex gap-3">
                                        {!aboutUs && !isLoggedIn && <Link to={HelperFunction.path.ABOUT_US}>
                                            <button className="btn btn-primary about-us-btn">About Us</button>
                                        </Link>}
                                        {loginPage && !isLoggedIn && <Link to={HelperFunction.path.SIGNUP}>
                                            <button className="btn btn-outline-success my-2 my-sm-0 px-4" type="submit">Sign up</button>
                                        </Link>}
                                        {!loginPage && !isLoggedIn && <Link to={HelperFunction.path.LOGIN}>
                                            <button className="btn btn-outline-success my-2 my-sm-0 px-4" type="submit">Login</button>
                                        </Link>}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
            <Suspense fallback={<CustomLoader />}>
                <SideNavToggle
                    userDetails={userDetails}
                    getUserDetails={getUserDetails}
                />
                <Notification notificationData={isArrayValue(notificationData)} />
            </Suspense>
        </>
    )
}

export default Header