import React from 'react'
import { useSelector } from 'react-redux';
import { isStringValue } from '../../utility-files/data-util/DataHandling';

function ComponentHandler({ children }) {
    const themeData = useSelector((state) => state.reduxReducer.themeData);

    const divCustomTheme = {
        background: isStringValue(themeData.backgroundColor),
        fontFamily: isStringValue(themeData.fontName),
    };

    return (
        <div id="contentCntr" style={divCustomTheme}>
            <div className="content-wrapper">
                <div className="container-fluid">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ComponentHandler
