import React from 'react'
import { isStringValue } from "../utility-files/data-util/DataHandling";
import { useSelector } from 'react-redux';

function TableContentHandler({ header, data }) {
    const themeData = useSelector((state) => state.reduxReducer.themeData);
    const elementStyle = {
        fontFamily: isStringValue(themeData?.fontName),
        color: isStringValue(themeData?.textColor)
    };
    return (
        isStringValue(header) && !isStringValue(data) ? <th style={elementStyle}>{isStringValue(header)}</th> : <p style={elementStyle}>{isStringValue(data)}</p>
    )
}

export default TableContentHandler
