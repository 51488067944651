import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { isStringValue } from '../utility-files/data-util/DataHandling';

function ThemeAnchorLink({ children, ...rest }) {
    const themeData = useSelector((state) => state.reduxReducer.themeData);
    const anchorLinkTheme = {
        color: isStringValue(themeData?.linkColor),
        fontFamily: isStringValue(themeData?.fontName)
    };
    
    return (
        <Link style={anchorLinkTheme} {...rest}>{children}</Link>
    )
}

export default ThemeAnchorLink
