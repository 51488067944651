import React, { useEffect, useState } from 'react'
import * as global from '../../constants/global'
import CommonTable from '../common-module/CommonTable';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import { isBooleanValue, isStringValue } from '../../utility-files/data-util/DataHandling';
import NoRecord from '../common-module/NoRecord';
import AddItemConfig from '../../custom-popup-model/AddItemConfig';
import { useAlert } from 'react-alert';
import { alertTypes } from '../../utility-files/helper-function/HelperFunction';
import CustomLoader from '../../custom-ui/CustomLoader';
import { getDateFormat, getTimeFormat } from '../../utility-files/date-util/DateHandling';
import Image from '../common-module/Image';
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';
import ComponentHandler from '../common-module/ComponentHandler';
import ThemeButton from '../../qed-theme-module/ThemeButton';
import TableContentHandler from '../../qed-theme-module/TableContentHandler';
import { useSelector } from 'react-redux';

function ItemConfiguration() {
    const alert = useAlert()
    const [isShowModel, setIsShowModel] = useState(false);
    const [configurationId, setConfigurationId] = useState(null);
    const [itemList, SetItemList] = useState(null);
    const [pending, setPending] = useState(false);
    const walletData = useSelector((state) => state.reduxReducer.walletData);

    useEffect(() => {
        getItemList();
    }, []);

    const getItemList = async () => {
        setPending(true)
        let variables = {}, res;
        let request = getRequestForApi(global.LIST_ITEM_CONFIGURATION, variables, methodType.POST);
        await callHttpRequest(request).then(response => {
            if (response?.status === 201) {
                res = response?.data?.response?.data
                SetItemList(res);
                setPending(false);
            };
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
        });
    };

    const handleConfig = () => {
        if (isStringValue(walletData?.userSubscriptionStatus)?.toLowerCase() === 'active') {
            setIsShowModel(!isShowModel)
        } else {
            alert.show('Your subscription has expired. Please renew subscription to use Item configuration.', { type: alertTypes.ERROR });
        };
    };

    const configurationColumns = [
        {
            name: <TableContentHandler header={'Created By'} />,
            selector: row => row?.createBy,
            sortable: true,
            cell: row => (<TableContentHandler data={row?.createBy} />),
        },
        {
            name: <TableContentHandler header={'Organization'} />,
            selector: row => row?.organisation,
            sortable: true,
            cell: row => (<TableContentHandler data={row?.organisation} />),
        },
        {
            name: <TableContentHandler header={'Create Date'} />,
            selector: row => row?.createDate,
            sortable: true,
            cell: row => (<TableContentHandler data={`${getDateFormat(row?.createDate)} ${getTimeFormat(row?.createDate)}`} />),
        },
        {
            name: <TableContentHandler header={'Update Date'} />,
            selector: row => row?.updateDat,
            sortable: true,
            cell: row => (<TableContentHandler data={`${getDateFormat(row?.updateDat)} ${getTimeFormat(row?.updateDat)}`} />),
        },
        {
            name: <TableContentHandler header={'Status'} />,
            selector: row => row?.status,
            sortable: true,
            cell: row => (<span data-tooltip={isBooleanValue(row?.status)}>
                <span className='ellipsis'>
                    {isBooleanValue(row?.status) ?
                        <span className="logged-in">●</span> :
                        <span className="logged-out">●</span>}
                </span>
            </span>),
        },
        {
            name: <th></th>,
            selector: row => row?.id,
            cell: row => (<Image className='quick-link-content-icon' src={IMAGE_TYPES.ARROW_RIGHT}
                onMouseOver={e => e.currentTarget.src = IMAGE_TYPES.SELECTED_ARROW}
                onMouseOut={e => e.currentTarget.src = IMAGE_TYPES.ARROW}
                alt={row?.id}
                onClick={() => { setIsShowModel(!isShowModel); setConfigurationId(row?.id); }}
            />)
        }
    ];
    return (
        <>
            <ComponentHandler>
                <div className="box table_structure item-data-table">
                    <div className="actionRow">
                        <div className="left">
                            <ThemeButton className="addButton pointer" onClick={() => handleConfig()}>
                                <i className="bi bi-plus-circle"></i>Add Item Configuration
                            </ThemeButton>
                        </div>
                    </div>
                    <CommonTable
                        columns={configurationColumns}
                        data={itemList}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={'300px'}
                        progressPending={pending}
                        progressComponent={<CustomLoader />}
                        noDataComponent={<NoRecord />}
                        onRowClicked={(row) => {
                            setIsShowModel(!isShowModel); setConfigurationId(row?.id);
                        }}
                    />
                </div>
            </ComponentHandler>
            <AddItemConfig isShowModel={isShowModel} setIsShowModel={setIsShowModel} configurationId={configurationId} setConfigurationId={setConfigurationId} onSuccess={getItemList} />
        </>
    )
}

export default ItemConfiguration
