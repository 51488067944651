import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import * as global from '../constants/global';
import { alertMessages, alertTypes, path, } from "../utility-files/helper-function/HelperFunction";
import { isStringValue } from "../utility-files/data-util/DataHandling";
import { callHttpRequest, methodType } from "../utility-files/api-util/HttpRequest";
import { getRequestForApi } from "../utility-files/api-util/CommonRequest";
import { useNavigate, useParams } from "react-router-dom";
import { decryptData } from "../utility-files/data-encryption-util/DataEncryption";
import { getDefaultValues } from "../utility-files/default-values/DefaultValues"
import ThemeButton from "../qed-theme-module/ThemeButton";

function ConfirmDelete() {
    const [pending, setPending] = useState(false)
    const alert = useAlert();
    const navigate = useNavigate()
    const [userData, setUserData] = useState();
    const [userConfirm, setUserConfirm] = useState(null)
    const params = useParams();
    useEffect(() => {
        if (params) {
            setUserData(params)
        }
    }, [params])

    const ConfirmAccount = async () => {
        let variables = {}
        variables.email = isStringValue(decryptData(params?.email))
        let request = getRequestForApi(global.CONFIRM_DELETE, variables, methodType.POST);
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                let data = getDefaultValues().ALERT_OPTIONS;
                data.passphrase = response?.data?.wallet?.passphrase[0];
                data.showButton = true;
                alert.show(alertMessages.DELETE_ACCOUNT, { type: alertTypes.SUCCESS });
                navigate(path.INVITE_ACCEPT_SUCCESS);
                setUserConfirm(data)
            }
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log("err", err)
        })
    }
    return (
        <>
            <form>
                <div className="form-row">
                    <h1>Once you delete your QED account, everything that was associated with your account will also be deleted.</h1>
                </div>
                <div className="form-row">
                    <ThemeButton type="button" className='btn1' onClick={() => ConfirmAccount()}>Delete Account</ThemeButton>
                </div>
            </form>
        </>
    )
}
export default ConfirmDelete