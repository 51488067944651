import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { decryptData, encryptData } from "../../utility-files/data-encryption-util/DataEncryption";
import { REDIRECT_URL } from "../../utility-files/default-values/DefaultValues";
import ThemeButton from "../../qed-theme-module/ThemeButton";

function CheckoutForm({ options, clientSecretId }) {
    const clientSecret = options?.clientSecret;
    const { secret } = useParams();
    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        retrievePaymentIntent();
    }, [stripe]);

    const retrievePaymentIntent = async () => {
        if (!stripe) {
            return;
        };

        if (!clientSecret) {
            return;
        };

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        let data;
        data = JSON.parse(decryptData(secret));
        data.clientSecretId = clientSecretId;
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                //Development
                // return_url: URL + encryptData(JSON.stringify(data)),
                //Testing
                return_url: REDIRECT_URL + encryptData(JSON.stringify(data)),
            },
        });
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };


    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <ThemeButton type="submit" className="btn btn-primary" disabled={!stripe || !elements}>
                Pay
            </ThemeButton>
            {/* Show error message to your customers */}
        </form>
    );
}

export default CheckoutForm