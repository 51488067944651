import React from 'react'
import ThemeButton from '../qed-theme-module/ThemeButton';

function DeleteConfig(props) {
    const { deleteConfigModal, heading, handleSubmit, handleClose } = props;
    return (
        <div className={`modal ${deleteConfigModal ? 'show' : 'fade'} bd-example-modal-sm page-over`} id="deleteConfig" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <p>
                            {heading}
                        </p>
                    </div>
                    <div className="modal-footer yesNoBtn">
                        <ThemeButton type="button" className="btn btn-secondary yesBtn" data-dismiss="modal" onClick={() => handleSubmit()}>Yes</ThemeButton>
                        <ThemeButton type="button" className="btn btn-primary noBtn" data-dismiss="modal" onClick={() => handleClose(null)}>No</ThemeButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfig
