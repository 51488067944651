import React from 'react'
import '../../assets/css/policy.css'
import { Link } from 'react-router-dom'
import { getHostUrl, openWindow, path } from '../../utility-files/helper-function/HelperFunction'

function Terms() {
    return (
        <section className="p-3 background">
            <div className="text-center">
                <h5 className="mt-3">
                    QED QED LLC <br />
                    TERMS AND CONDITIONS for BUSINESS WEB APP
                </h5>
            </div>
            <div>
                <p>QED QED LLC (“QED” “we,” “our,” or “us”) is a service that allows the user to create or transfer NFTS
                    (non-fungible tokens) on a blockchain as digital representations of digital or real-world items.
                    This service is currently provided free of charge and QED cannot accept responsibility for any loss
                    or inconvenience caused by reliance on any material on this application. QED provides this
                    application and its contents (the “Service”), for use on iOS or Android mobile devices, subject to
                    the following Terms of Use (the “Terms”). These Terms shall apply to any person (referred to herein
                    as “you” or “your”) who accesses or uses any feature of the Service. If you are using this Service
                    on behalf of a business, corporation, partnership, or other entity, you agree to these Terms for
                    that business, corporation, partnership, or entity and represent that you have the authority to do
                    so (in that case, “you” and “your” refers to that business, trust or entity). BY USING THE APP, YOU
                    AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS AGREEMENT OR, IF YOU ARE NOT, THAT YOU HAVE
                    OBTAINED THE CONSENT OF A PARENT OR GUARDIAN TO ENTER INTO THIS AGREEMENT. IF YOU ARE UNDER 13 YEARS
                    OLD, YOU ARE NOT PERMITTED TO USE THE APP.</p>
            </div>
            <div>
                <p>YOU SHOULD READ THESE TERMS AND CONDITIONS BECAUSE THEY MAY LIMIT YOUR LEGAL RIGHTS. PLEASE REVIEW
                    THE ARBITRATION TERMS BELOW AS THEY WILL REQUIRE YOU TO AGREE TO BINDING ARBITRATION AND A WAIVER OF
                    CLASS ACTIONS. THESE TERMS APPLY TO ALL VISITORS, USERS AND OTHERS WHO ACCESS THIS SERVICE
                    (“users”). BY VISITING OR USING THIS SITE, YOU EXPRESSLY AGREE TO ALL THE TERMS AND CONDITIONS OF
                    THIS AGREEMENT.</p>
            </div>
            <div className="listing">
                <p>For an explanation of our practices and policies related to the collection, use and storage of
                    Service users’ information, please read our <Link to={'#'} onClick={() => openWindow(getHostUrl() + path.PRIVACY_POLICY)}>Privacy Policy</Link>, which is incorporated herein by reference.</p>
                <ol>
                    <li><b>Limited License.</b>You are granted a (revocable) non-exclusive, non-sublicensable,
                        non-transferable, personal, limited license to install and use the QED Web App (“Software”)—NOT
                        REALLY DEFINED only on a mobile device that you own or control, solely for your use and as
                        expressly permitted herein. All rights not expressly granted to you herein are reserved. Or
                        license to use the Services. You’re access and use of the Servicesis conditioned on your
                        compliance with this Agreement.</li>
                    <li><b>Wallet. </b>A wallet and unique credentials are created for the user of this Service. The
                        tokens
                        are authenticated and hosted securely in the wallet for receipt of the tokens, and may be
                        transferred to a third party. </li>
                    <li><b>No Fees. </b>There is no fee or charge for using the Service. Regular account charges will
                        apply
                        to services and features that are accessible through the Service. The telecommunications carrier
                        for your Eligible Mobile Device—DEFINITION? may impose an extra fee in order to make such device
                        wireless web enabled.</li>
                    <li><b>Access Device.</b>By identifying a cell phone or other device as an Eligible Mobile Device
                        for
                        use with the Service, QED does not recommend, endorse or make any representation or warranty of
                        any kind regarding the performance or operation of such device. You are responsible for the
                        selection of an Eligible Mobile Device and for all issues relating to the operation, performance
                        and costs associated with such device with your telecommunications carrier.
                        <br />
                        <br />
                        <p>Other features of the Service may request access to other information contained or generated
                            in your device including location services in order to function properly. If you deny access
                            to these functions these services may not work as designed.</p>
                    </li>
                    <li><b>Information Submitted Through the Site.</b>Your submission of information through the Site is
                        governed by our Privacy Policy, which is located at [insert hyperlink to Privacy Policy]. You
                        agree that all information that you provide to us is true, accurate and complete and that you
                        will update such information as necessary</li>
                    <li>You may not transfer, or sell access to, your account. We will not be liable for any harm
                        related to disclosure of your username or password. You agree that QED will not be held
                        responsible for any unauthorized access to the Services (or any harm resulting from such
                        unauthorized access)</li>
                    <li><b>Errors and Changes in Service. </b>QED Business has no obligation to correct any bugs,
                        defects or
                        errors in the Service or Software, or to otherwise support, maintain, improve, modify, upgrade,
                        update or enhance the Service or Software. Except as otherwise required by applicable law or
                        regulation, QED Business, at any time in its sole discretion without prior notice, may (i)
                        terminate your use of the Service, and (ii) expand, reduce or suspend the type of activity
                        allowed using the Service.</li>
                    <li><b>Data Collection, Use, Sale and Disclosure. Solely to the extent permitted by applicable law
                        and regulation,</b> You acknowledge and agree that QED Business may collect, transmit, and
                        store, and sell technical, location, and login or other personal data and related information,
                        including but not limited to technical information about your device, system and application
                        software, and peripherals, and information regarding your location (including, for certain
                        services, your precise physical location), that is gathered periodically to facilitate the
                        provision of software updates, product support, and other services to you (if any) related to,
                        or in connection with, the Service. You also acknowledge that QED may sell such data collection.
                        <br />
                        <br />
                        The Service will enable you to create, hold, receive or transfer a token or tokens(s) from the
                        QED business app. This token may be held in a wallet for the secure download and transfer of
                        your token(s) and enhance user experience. QED will collect information for your security and
                        benefit of all token holders, and to offer you a secure way of holding and transferring your
                        token, and to guard against counterfeiting.
                        <br />
                        <br />
                        We may use this information to provide or enhance the specific products or services, to
                        facilitate the operation of our Service, to help us understand how our users use our Service,
                        for our internal analytics and reporting, authentication, and security (including fraud
                        prevention), and to improve the functionality of this Service. We may disclose your information
                        and location to our suppliers and agents who assist us in providing the Service to you. QED and
                        these suppliers and agents may access, use, or disclose your information to meet legal or
                        regulatory obligations, including responding to requests from government, regulators, courts,
                        and law enforcement authorities, to the extent permitted by applicable laws and regulations.
                        <br />
                        <br />
                        The ability to collect certain personal information is controlled by your device. For example,
                        your device may control the ability to disclose your device's geolocation or provide access to
                        your contacts, photos and/or camera. Please refer to the documentation for your device regarding
                        how to allow or block the collection of location or certain device information. If you choose
                        not to provide location or certain device information, some services you request may not operate
                        effectively.
                        <br />
                        <br />
                        You authorize your wireless operator to disclose your mobile number, name, address, email,
                        network status, customer type, customer role, billing type, mobile device identifiers (IMSI and
                        IMEI) and other subscriber status details, if available, solely to allow verification of your
                        identity and to compare information you have provided to QED with your wireless operator account
                        profile information for the duration of your business relationship with QED.
                    </li>

                    <li><b>Third-Party Services and APIs.</b>Some of the content appearing to be on this Service is in
                        fact supplied, supported, or provided directly or indirectly by third parties. The Service may
                        provide features you access through other third-party services using software links or
                        application programming interfaces (APIs”). Any such link does not imply any endorsement by QED
                        or any affiliation with its operator. Such links may be blocked by QED at any time. Your use of
                        such services is subject to the terms of use and privacy policies of such services.</li>
                    <li><b>Prohibited Uses.</b>You agree that you will not use the Service, or any services related
                        thereto for any purposes prohibited by United States law and shall not use or otherwise export
                        or re-export this application or its software, except as authorized by United States law and the
                        laws of the jurisdiction in which the Service was obtained. You hereby represent and warrant (i)
                        you are not located in a country that is subject to a U.S. Government embargo or has been
                        designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not
                        listed on any U.S. Government list of prohibited or restricted parties.</li>
                    <li><b>Authority and Accuracy.</b>In consideration of your visiting and using the Service, you
                        represent that you are able to form a binding contract on behalf of yourself or the business,
                        corporation, partnership or entity you represent. You also agree that any information you
                        provide is accurate and complete information at the time provided. You agree that you will not
                        impersonate any person or entity whether actual or fiction. You agree to update any information
                        provided to QED to keep it accurate and complete. Failure to timely and accurately provide
                        updated information about you is reasonable grounds for QED to suspend or terminate your account
                        and to refuse you service in the future. You must be at least 18 years of age to use this
                        Service. QED does not knowingly collect personally identifiable information from children under
                        the age of 13.</li>
                    <li><b>Password Restricted Areas of the Service. </b>Certain areas of the Service may be password
                        restricted to QED customers or registered users ("Password-Protected Areas"). If you have
                        registered as an authorized user to gain access to these Password-Protected Areas, you agree
                        that you are entirely responsible for maintaining the confidentiality of your password and agree
                        to notify QED if the password is lost, stolen, disclosed to an unauthorized third party, or
                        otherwise may have been compromised. You agree that you are entirely responsible for all
                        activities that occur under your account, whether or not you are the individual who undertakes
                        such activities. You agree to immediately notify QED of any unauthorized use of your account or
                        any other breach of security in relation to your password or the Service that is known to you.
                    </li>
                    <li><b>Errors and Changes in Service.</b>QED has no obligation to correct any bugs, defects or
                        errors in the Service or software, or to otherwise support, maintain, improve, modify, upgrade,
                        update or enhance the Service or Software. Except as otherwise required by applicable law or
                        regulation, QED, at any time in its sole discretion without prior notice, may (i) terminate your
                        use of the Service, and (ii) expand, reduce or suspend the type of activity allowed using the
                        Service.</li>
                    <li><b>Service Updates. </b>From time to time, we may issue upgraded versions of the Service. You
                        agree that these Terms will apply to all such upgrades. Any third-party code that may be
                        incorporated in the Service is covered by the applicable open source or third-party license
                        EULA, if any, authorizing use of such code. We may add or remove features, and while we will do
                        our best to give you advance notice about any major changes, you understand that we may stop,
                        suspend, or change the Service at any time without prior notice. We may also remove content from
                        the Service at our discretion. Any attempt by you to transfer any of the rights, duties, or
                        obligations hereunder, except as expressly provided for in these Terms, is void.</li>
                    <li><b>Copyrights and Protected Content. </b>All content and functionality on the Service, including
                        text, graphics, logos, icons, images, and videos and the selection and arrangement thereof, in
                        addition to any concepts, know-how, tools, frameworks, software, applications or other
                        technology, algorithms, models, processes, and industry perspectives underlying or embedded in
                        the foregoing, along with any enhancements to or derivative works thereof (the “Service
                        Content”) is the exclusive property of QED or its licensors and, to the extent applicable, is
                        protected by U.S. and international copyright laws, or other applicable intellectual property
                        laws. Neither the Service Content nor functionality of the Service, may be copied, reproduced,
                        modified, reverse engineered, altered (including the removal or disabling of any security or
                        technological safeguards, disclaimers, or legends) uploaded, published, posted, transmitted, or
                        distributed in any way without our written permission, except for those uses specified in
                        Section 1 (Limited License). All rights not expressly granted are reserved. PLEASE NOTE THAT ANY
                        UNAUTHORIZED USE OF THE CONTENTS OF THIS SERVICE, OR ANY SOFTWARE UTILIZED BY THIS SERVICE,
                        COULD EXPOSE YOU TO CIVIL OR CRIMINAL PENALTIES (THIS MAY INCLUDE MONETARY DAMAGES) FOR
                        COPYRIGHT INFRINGEMENT OR VIOLATION OF OTHER APPLICABLE INTELLECTUAL PROPERTY.

                        <p>You hereby represent and warrant that your user name and all other content does not infringe
                            any intellectual property, and You hereby grant QED unfettered right to change Your user
                            name and all other content to ensure that such does not infringe or violate the rights of
                            any third party.</p>
                    </li>
                    <li><b>Trademarks.</b>The trademarks, service marks, designs, and logos of QED (collectively, the
                        “Trademarks”) displayed on the Service are the registered and unregistered Trademarks of QED and
                        its licensors. You agree that, except as expressly permitted by us or by our licensors, where
                        applicable, you will not refer to or attribute any information to QED or its licensors in any
                        public medium (e.g., press release, WebServices, or public social media) for advertising or
                        promotion purposes, or for the purpose of informing or influencing any third party and that you
                        will not use or reproduce any Trademark of, or imply any endorsement by or relationship with,
                        QED or its licensors. Trademarks, service marks, entity names, designs, or logos of entities
                        other than QED appearing on the Service are trademarks of their respective holders; unless
                        otherwise explicitly stated, such appearance is not meant to imply endorsement by or any other
                        relationship with such entities.</li>
                    <li><b>Your Suggestions. </b>To the extent you provide us with any suggestions, ideas, proposals, or
                        similar materials, whether regarding the content of this Service or otherwise they will be
                        treated as a voluntary submission. You agree and represent that such submission is not
                        confidential. If you provide a submission to us, it is unsolicited, gratuitous and without
                        restriction and does not create any obligation to QED.</li>
                    <li><b>Data Mining, Commercial Use and Other Prohibited Uses. </b>You acknowledge that you are
                        expressly prohibited from utilizing this Service in connection with data mining of any kind,
                        commercial use, the sending of unsolicited electronic mail or other forms of messages, whether
                        commercial or not, to a large number of recipients. This prohibition extends to the sending of
                        unsolicited mass mailings from another service which in any way implicates the use of this
                        Service or QED. You may not use the Service, or the content or information delivered through the
                        Service in any way that would infringe any third-party copyright, patent, trademark, trade
                        secret, or other intellectual property or proprietary rights or that would violate any
                        applicable law or regulation. You may not use, frame, or utilize framing techniques to enclose
                        any QED trademark, logo or trade name or other proprietary information including the images
                        found at the Service, the content of any text or the layout/design of any page or any form
                        contained on a page without the express written consent of QED.</li>
                    <li><b>Limitation of Liability. </b>You expressly understand and agree that:
                        <ol type="A">
                            <li>YOUR USE OF THE SERVICE AND ALL INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THAT
                                OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICE IS AT YOUR SOLE RISK. THE
                                SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. YOU ACKNOWLEDGE THAT FROM
                                TIME TO TIME, THE SERVICE MAY BE DELAYED, INTERRUPTED OR DISRUPTED FOR AN INDETERMINATE
                                PERIOD OF TIME DUE TO CIRCUMSTANCES BEYOND THE REASONABLE CONTROL OF QED INCLUDING,
                                WITHOUT LIMITATION, ANY INACCURACY, INTERRUPTION OR DELAY IN TRANSMISSION BY THE
                                TELECOMMUNICATIONS CARRIER USED WITH THE ELIGIBLE MOBILE DEVICE TO ACCESS THE SERVICE,
                                OR ANY INTERRUPTION, DISRUPTION OR FAILURE IN THE PROVISION OF THE SERVICE, WHETHER
                                CAUSED BY STRIKES, POWER FAILURES, EQUIPMENT MALFUNCTIONS OR OTHER REASONS. qed
                                EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND AS TO THE SERVICE AND ALL INFORMATION,
                                PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE
                                FROM THE SERVICE, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
                                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
                            </li>
                            <li>QED MAKES NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE
                                WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE
                                OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF
                                ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE
                                SERVICE WILL MEET YOUR EXPECTATIONS, OR (V) ANY ERRORS IN THE TECHNOLOGY WILL BE
                                CORRECTED. YOU WILL USE THE SITE AT YOUR OWN RISK.</li>
                            <li>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT
                                YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                                DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
                                INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM QED THROUGH OR FROM THE
                                SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS. YOU ACKNOWLEDGE
                                THAT NEITHER WE NOR THE SERVICE WE PROVIDE OFFERS LEGAL, TAX OR FINANCIAL ADVICE AND WE
                                ARE NOT A FINANCIAL PLANNER, BROKER, OR TAX ADVISOR.</li>
                            <li>YOU AGREE THAT QED WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                                CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
                                PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, EVEN IF WE HAVE BEEN ADVISED OF
                                THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE
                                SERVICE; (ii) THE COST OF GETTING SUBSTITUTE SERVICES, (iii) ANY DATA, INFORMATION OR
                                SERVICES OBTAINED OR MESSAGES RECEIVED, THROUGH OR FROM THE SERVICE; (iv) UNAUTHORIZED
                                ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (v) STATEMENTS OR CONDUCT OF
                                ANYONE ON THE SERVICE; (vi) THE USE, INABILITY TO USE, UNAUTHORIZED USE, PERFORMANCE OR
                                NON-PERFORMANCE OF ANY THIRD PARTY ACCOUNT PROVIDER SERVICE, EVEN IF THE PROVIDER HAS
                                BEEN ADVISED PREVIOUSLY OF THE POSSIBILITY OF SUCH DAMAGES; OR (vii) ANY OTHER MATTER
                                RELATING TO THE SERVICE. IN NO EVENT WILL THE TOTAL, CUMULATIVE LIABILITY OF QED FOR
                                DAMAGES UNDER THESE TERMS EXCEED THE COST OF ANY SUBSCRIPTION THAT YOU MAY HAVE PAID
                                QED. <br />
                                <br />
                                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
                                EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
                                ABOVE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
                            </li>
                        </ol>
                    </li>
                    <li><b>Indemnification. </b>You agree to defend, indemnify, and hold harmless QED, its affiliates
                        and their respective directors, officers, employees, licensors and agents from and against all
                        claims, losses, damages, costs and expenses, including attorneys’ fees, made by you or any other
                        person arising from your use of this Service or the contents and materials thereof, your
                        violation or breach of the Terms, or your violation of any applicable federal or state laws or
                        regulations.</li>
                    <li><b>Security of Information. </b>We and our service providers maintain physical, electronic, and
                        procedural safeguards that comply with applicable federal standards to guard your personal
                        information provided through the Service.</li>
                    <li><b>Retention of Information. </b>We will retain your personal information, both the information
                        you provide directly, and the information obtained from third party Services as long as you are
                        enrolled in the Service. If you cancel the Service, we will discontinue the collection of
                        information from third party Services on your behalf. Upon cancellation of the Service or
                        deletion of an account or institution from the Service we will delete and direct our third-party
                        service provider to delete this data from our and their respective records. This data will be
                        maintained with the same security and privacy controls as are in effect when you are enrolled in
                        the Service.</li>
                    <li><b>Notification Procedures and Changes to these Terms. </b>QED may provide notifications,
                        whether such notifications are required by law or are for marketing or other business related
                        purposes, to you via email notice, written or hard copy notice, or through posting of such
                        notice on our website qedvault.com XXX, as determined by QED. We reserve the right to determine
                        the form and means of providing notifications to our users, provided that you may opt out of
                        certain means of notification as described in these Terms. QED is not responsible for any
                        automatic filtering you or your network provider may apply to email notifications we send to the
                        email address you provide us. <br />
                        <br />
                        Nothing in this Section shall prevent either party from seeking injunctive or other equitable
                        relief from the courts as necessary to prevent the actual or threatened infringement,
                        misappropriation, or violation of that party’s data security, intellectual property rights, or
                        other proprietary rights. All claims must be brought in the parties’ individual capacity, and
                        not as a plaintiff or class member in any purported class or representative proceeding, and,
                        unless we agree otherwise, the arbitrator may not consolidate more than one person’s claims. You
                        agree that, by entering into this agreement, you and QED are each waiving the right to a trial
                        by jury or to participate in a class action.
                    </li>
                    <li><b>Miscellaneous.</b>
                        <ol type="a">
                            <li>These Terms, the relationship between you and QED and any arbitration shall be governed
                                by the laws of the State of New York, without regard to its conflict of law provisions.
                                You and QED agree to submit to the personal and exclusive jurisdiction of the courts
                                located within New York County, New York.</li>
                            <li>You agree that, except as otherwise expressly provided in the Terms, there shall be no
                                third-party beneficiaries to this agreement.</li>
                            <li>The failure of QED to exercise or enforce any right or provision of these Terms shall
                                not constitute a waiver of such right or provision. If any provision of the Terms of Use
                                is found by a court of competent jurisdiction to be invalid, the other terms and
                                provisions of the Terms shall remain in full force and effect.</li>
                            <li>You agree that your qed business user account is non-transferable and your access to
                                your account or its contents may be terminated at any time in QED’S sole discretion for
                                breach of these Terms, fraudulent or illegal activity or based on inactivity and lack of
                                transactions on the Service.</li>
                            <li>If you select a username or similar identifier to your account, we may change it or
                                require you to change it if we believe it is appropriate or necessary (for example. If
                                it infringers someone’s intellectual property or impersonates another user).</li>
                            <li>You agree that any claim or cause of action that you have arising out of or related to
                                use of any services provided on our Service, or these Terms must be filed within one (1)
                                year after the date that such claim or cause of action arose or be forever barred.</li>
                            <li>These Terms constitute the entire agreement between you and QED and govern your use of
                                the Service. They may not be amended, except by a signed written agreement between QED
                                and any user.</li> <br />
                            <br />
                            Your Questions. If you have questions regarding these Terms of Use, you should contact QED
                            by e-mailing [legal@ qedvault.comXXX] and writing "Terms of Use" in the subject line. You
                            may also write to QED XXXX New York, New York XXX. If you have disputes about the accuracy
                            of any account information displayed or believe that someone who is not authorized to do so
                            has accessed information through the Service, please contact QED at (929) 258-6022 or your
                            QED account officer.
                        </ol>
                    </li>
                </ol>
            </div>
        </section>
    )
}

export default Terms
