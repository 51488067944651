import React, { Suspense, lazy } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { storageKey, getStorageData, storageType } from '../utility-files/storage-util/StorageHandling';
import { path } from '../utility-files/helper-function/HelperFunction';
import CustomLoader from '../custom-ui/CustomLoader';
const Header = lazy(() => import('../common-pages/Header'));

export const PrivateRoute = (props) => {
    const isWelcomePage = window.location.pathname === path.WELCOME;
    const token = getStorageData(storageType, storageKey.TOKEN) ? getStorageData(storageType, storageKey.TOKEN) : getStorageData(!storageType, storageKey.TOKEN);
    return <>
        <Suspense fallback={<CustomLoader />}>
            <Header />
            {isWelcomePage && !token && <Outlet {...props} />}
            {!isWelcomePage && token ? <Outlet {...props} /> : <Navigate replace to={path.WELCOME} />}
        </Suspense>
    </>
}


export default PrivateRoute