const $ = window.jQuery = require('jquery')

// $(document).on('click', '.fileUploadModal', function () {
//     $('#fileUploadModal').modal('show');
//     $("#AddItem").removeClass('show');
// });

// $(document).on('click', '.modal-close', function () {
//     $('#AddItem').modal('show');
// });

$(document).on('click', '.close-name-modal', function () {
  $('#Profile').modal('show');
});

$(document).on('click', '.close-email', function () {
  $('#Profile').modal('show');
});

$(document).on('click', '.close-auth', function () {
  $('#Profile').modal('show');
});

$(document).on("click", '.close-filter-modal', function () {
  $('#filter-modal').modal('show');
});

$(document).on("click", '.close-filter-modal-popup', function () {
  $('#filter-modal-popup').modal('show');
});

$(document).on("click", '.btn-close-forgot-password', function () {
  $('#forgotPasswordModal').modal('show');
});

$(document).on("Click", '.btn-close-notification-modal', function () {
  $('#NotificationModal').modal('show')
})

$(document).on("click", '.btn-Cancle-invite', function () {
  $('#sendInviteModal').modal('show');
});

$(document).on("click", ".browse1", function () {
  var file = $(this).parents().find(".file2");
  file.trigger("click");
});
$(document).on('change', '#image-form1 input[type="file"]', function (e) {
  var fileName = e.target.files[0].name;
  $("#file1").val(fileName);

  var reader = new FileReader();
  reader.onload = function (e) {
    document.getElementById("preview1").src = e.target.result;
  };
  reader.readAsDataURL(this.files[0]);
});
/** Image upload 1 **/
$(document).on("click", ".browse", function () {
  var file = $(this).parent().parent().parent().find(".file");
  file.trigger("click");
});
$(document).on('change', '#image-form input[type="file"]', function (e) {
  var fileName = e.target.files[0].name;
  $("#file").val(fileName);


  var reader = new FileReader();
  reader.onload = function (e) {
    if (document.getElementById("preview")) {
      document.getElementById("preview").src = e.target.result;
    }
  };
  reader.readAsDataURL(this.files[0]);
});


// $(document).on('click', ".addnoteBtn", function () {
//   $(".notes").show();
// });

$(document).on('click', ".close-notes", function () {
  $('.notes').hide();
});

$(document).on('click', ".close-sidenav", function () {
  $('body').removeAttr('style');
  $('.modal').modal('hide');
  $('body').removeClass('modal-open');
  $('.modal-backdrop').remove();
});

// DELETE TOKEN CONFIRMATION POPUP MODAL/////// 
// $(document).on('click', ".trash_icon", function () {
//   $('#deleteToken').addClass('show');
// });

// $(document).on('click', ".delete", function () {
//   $('#deleteToken').addClass('show');
// });

// $(document).on('click', ".delete-token", function () {
//   $('#deleteToken').addClass('show');
// });

// $(document).on('click', ".delete-item", function () {
//   $('#deleteToken').addClass('show');
// });

$(document).on('click', ".noBtn", function () {
  $("#deleteToken").removeClass('show');
});

$(document).on('click', ".print-QR", function () {
  $("#printQR").addClass('show');
});

$(document).on('click', ".close", function () {
  $("#printQR").removeClass('show');
});

$(document).on('click', ".close-select-filter", function () {
  $(".searchFilter").hide();
  $(".pageoverlay").hide();
});

$(document).on('click', ".selectClick", function (e) {
  if ($("#select-token").hasClass('show')) {
    $("#select-token").removeClass('show');
  } else {
    $("#select-token").addClass('show');
  }
});

$("body").on('click', function (e) {
  if ($("#select-token").hasClass('show')) {
    $("#select-token").removeClass('show');
  }
});

$(document).on('click', ".transferaction", function () {
  $(".searchFilter").hide();
  $(".pageoverlay").hide();
});

$(document).on('click', ".filter2", function () {
  $(".searchFilter").hide();
  $(".pageoverlay").hide();
});

$(document).on('click', ".pageoverlay", function () {
  $(".searchFilter").hide();
  $(".pageoverlay").hide();
});

$('#btn-file-reset').on('click', "", function () {
  $('#fileInput').val('');
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})