function HomeLogo({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5 18.5C16.6 18.5 17.5 17.6 17.5 16.5V7.5C17.5 6.4 16.6 5.5 15.5 5.5C14.4 5.5 13.5 6.4 13.5 7.5V16.5C13.5 17.6 14.39 18.5 15.5 18.5Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.5 18.5C9.6 18.5 10.5 17.6 10.5 16.5V13C10.5 11.9 9.6 11 8.5 11C7.4 11 6.5 11.9 6.5 13V16.5C6.5 17.6 7.39 18.5 8.5 18.5Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>);
}

function ItemsLogo({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.93014 2.48028L4.59014 5.44028C3.38014 6.11028 2.39014 7.79028 2.39014 9.17028V14.8203C2.39014 16.2003 3.38014 17.8803 4.59014 18.5503L9.93014 21.5203C11.0701 22.1503 12.9401 22.1503 14.0801 21.5203L19.4201 18.5503C20.6301 17.8803 21.6201 16.2003 21.6201 14.8203V9.17028C21.6201 7.79028 20.6301 6.11028 19.4201 5.44028L14.0801 2.47028C12.9301 1.84028 11.0701 1.84028 9.93014 2.48028Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.16992 7.43994L11.9999 12.5499L20.7699 7.46994" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 21.61V12.54" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>);
}

function TokenIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 9V7C2 4 4 2 7 2H17C20 2 22 4 22 7V9" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 15V17C2 20 4 22 7 22H17C20 22 22 20 22 17V15" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.76 6.29006L7.55998 8.07009C6.83998 8.47009 6.23999 9.48008 6.23999 10.3101V13.7001C6.23999 14.5301 6.82998 15.5401 7.55998 15.9401L10.76 17.7201C11.44 18.1001 12.56 18.1001 13.25 17.7201L16.45 15.9401C17.17 15.5401 17.77 14.5301 17.77 13.7001V10.3101C17.77 9.48008 17.18 8.47009 16.45 8.07009L13.25 6.29006C12.56 5.90006 11.44 5.90006 10.76 6.29006Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.7002 9.26001L12.0002 12.33L17.2602 9.28003" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.0002 17.7701V12.3201" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function UsersIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1404 21.62C17.2604 21.88 16.2204 22 15.0004 22H9.00035C7.78035 22 6.74035 21.88 5.86035 21.62C6.08035 19.02 8.75035 16.97 12.0004 16.97C15.2504 16.97 17.9204 19.02 18.1404 21.62Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5799 10.58C15.5799 12.56 13.9799 14.17 11.9999 14.17C10.0199 14.17 8.41992 12.56 8.41992 10.58C8.41992 8.60002 10.0199 7 11.9999 7C13.9799 7 15.5799 8.60002 15.5799 10.58Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function FeedbackIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.0399 3.01976L8.15988 10.8998C7.85988 11.1998 7.55988 11.7898 7.49988 12.2198L7.06988 15.2298C6.90988 16.3198 7.67988 17.0798 8.76988 16.9298L11.7799 16.4998C12.1999 16.4398 12.7899 16.1398 13.0999 15.8398L20.9799 7.95976C22.3399 6.59976 22.9799 5.01976 20.9799 3.01976C18.9799 1.01976 17.3999 1.65976 16.0399 3.01976Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.9102 4.1499C15.5802 6.5399 17.4502 8.4099 19.8502 9.0899" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    )
}

function ConfigurationIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5698 18.5001V14.6001" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5698 7.45V5.5" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5702 12.65C17.0062 12.65 18.1702 11.4859 18.1702 10.05C18.1702 8.61401 17.0062 7.44995 15.5702 7.44995C14.1343 7.44995 12.9702 8.61401 12.9702 10.05C12.9702 11.4859 14.1343 12.65 15.5702 12.65Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.43018 18.5V16.55" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.43018 9.4V5.5" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.43008 16.5501C9.86602 16.5501 11.0301 15.386 11.0301 13.9501C11.0301 12.5142 9.86602 11.3501 8.43008 11.3501C6.99414 11.3501 5.83008 12.5142 5.83008 13.9501C5.83008 15.386 6.99414 16.5501 8.43008 16.5501Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function QuickLinksIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 12V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H12" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.96 17.84L19.33 18.39C18.88 18.54 18.52 18.89 18.37 19.35L17.82 20.98C17.35 22.39 15.37 22.36 14.93 20.95L13.08 15C12.72 13.82 13.81 12.72 14.98 13.09L20.94 14.94C22.34 15.38 22.36 17.37 20.96 17.84Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function TokensIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 9V7C2 4 4 2 7 2H17C20 2 22 4 22 7V9" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 15V17C2 20 4 22 7 22H17C20 22 22 20 22 17V15" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.76 6.29006L7.55998 8.07009C6.83998 8.47009 6.23999 9.48008 6.23999 10.3101V13.7001C6.23999 14.5301 6.82998 15.5401 7.55998 15.9401L10.76 17.7201C11.44 18.1001 12.56 18.1001 13.25 17.7201L16.45 15.9401C17.17 15.5401 17.77 14.5301 17.77 13.7001V10.3101C17.77 9.48008 17.18 8.47009 16.45 8.07009L13.25 6.29006C12.56 5.90006 11.44 5.90006 10.76 6.29006Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.7002 9.26001L12.0002 12.33L17.2602 9.28003" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.0002 17.7701V12.3201" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function ItemsIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.93014 2.48028L4.59014 5.44028C3.38014 6.11028 2.39014 7.79028 2.39014 9.17028V14.8203C2.39014 16.2003 3.38014 17.8803 4.59014 18.5503L9.93014 21.5203C11.0701 22.1503 12.9401 22.1503 14.0801 21.5203L19.4201 18.5503C20.6301 17.8803 21.6201 16.2003 21.6201 14.8203V9.17028C21.6201 7.79028 20.6301 6.11028 19.4201 5.44028L14.0801 2.47028C12.9301 1.84028 11.0701 1.84028 9.93014 2.48028Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.17004 7.43994L12 12.5499L20.77 7.46994" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 21.61V12.54" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function SearchIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22 22L20 20" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function FilterIcon({ fillColor }) {
    return (<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 1H2C1.44772 1 1 1.44772 1 2V3.58579C1 3.851 1.10536 4.10536 1.29289 4.29289L6.70711 9.70711C6.89464 9.89464 7 10.149 7 10.4142V16.382C7 17.1253 7.78231 17.6088 8.44721 17.2764L10.4472 16.2764C10.786 16.107 11 15.7607 11 15.382V10.4142C11 10.149 11.1054 9.89464 11.2929 9.70711L16.7071 4.29289C16.8946 4.10536 17 3.851 17 3.58579V2C17 1.44772 16.5523 1 16 1Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function FAQsIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.4701 16.83L18.8601 19.99C18.9601 20.82 18.0701 21.4 17.3601 20.97L13.1701 18.48C12.7101 18.48 12.2601 18.45 11.8201 18.39C12.5601 17.52 13.0001 16.42 13.0001 15.23C13.0001 12.39 10.5401 10.09 7.50009 10.09C6.34009 10.09 5.2701 10.42 4.3801 11C4.3501 10.75 4.34009 10.5 4.34009 10.24C4.34009 5.68999 8.29009 2 13.1701 2C18.0501 2 22.0001 5.68999 22.0001 10.24C22.0001 12.94 20.6101 15.33 18.4701 16.83Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 15.23C13 16.42 12.56 17.5201 11.82 18.3901C10.83 19.5901 9.26 20.36 7.5 20.36L4.89 21.91C4.45 22.18 3.89 21.81 3.95 21.3L4.2 19.3301C2.86 18.4001 2 16.91 2 15.23C2 13.47 2.94 11.9201 4.38 11.0001C5.27 10.4201 6.34 10.0901 7.5 10.0901C10.54 10.0901 13 12.39 13 15.23Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function FAQsSelectedIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.4698 16.83L18.8598 19.99C18.9598 20.82 18.0698 21.4 17.3598 20.97L13.1698 18.48C12.7098 18.48 12.2599 18.45 11.8199 18.39C12.5599 17.52 12.9998 16.42 12.9998 15.23C12.9998 12.39 10.5398 10.09 7.49985 10.09C6.33985 10.09 5.26985 10.42 4.37985 11C4.34985 10.75 4.33984 10.5 4.33984 10.24C4.33984 5.68999 8.28985 2 13.1698 2C18.0498 2 21.9998 5.68999 21.9998 10.24C21.9998 12.94 20.6098 15.33 18.4698 16.83Z" fill={fillColor ? fillColor : "#8C8F90"} stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 15.2298C13 16.4198 12.56 17.5198 11.82 18.3898C10.83 19.5898 9.26 20.3598 7.5 20.3598L4.89 21.9098C4.45 22.1798 3.89 21.8098 3.95 21.2998L4.2 19.3298C2.86 18.3998 2 16.9098 2 15.2298C2 13.4698 2.94 11.9198 4.38 10.9998C5.27 10.4198 6.34 10.0898 7.5 10.0898C10.54 10.0898 13 12.3898 13 15.2298Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function NotificationIcon({ fillColor, newNotification }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0201 2.90991C8.71009 2.90991 6.02009 5.59991 6.02009 8.90991V11.7999C6.02009 12.4099 5.76009 13.3399 5.45009 13.8599L4.30009 15.7699C3.59009 16.9499 4.08009 18.2599 5.38009 18.6999C9.69009 20.1399 14.3401 20.1399 18.6501 18.6999C19.8601 18.2999 20.3901 16.8699 19.7301 15.7699L18.5801 13.8599C18.2801 13.3399 18.0201 12.4099 18.0201 11.7999V8.90991C18.0201 5.60991 15.3201 2.90991 12.0201 2.90991Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" />
        {newNotification && <circle cx="17.5" cy="6.5" r="4.5" fill="#DA1F2A" />}
    </svg>)
}

function GenerateTokenIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.70001 9.25977L12 12.3298L17.26 9.27976" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 17.7698V12.3198" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.76 6.28986L7.56 8.06986C6.84 8.46986 6.23999 9.47985 6.23999 10.3099V13.6999C6.23999 14.5299 6.83 15.5399 7.56 15.9399L10.76 17.7199C11.44 18.0999 12.56 18.0999 13.25 17.7199L16.45 15.9399C17.17 15.5399 17.77 14.5299 17.77 13.6999V10.2999C17.77 9.46986 17.18 8.45985 16.45 8.05985L13.25 6.27985C12.56 5.89985 11.44 5.89986 10.76 6.28986Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 9C2 5.13 5.13 2 9 2L7.95001 3.75" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function TransferTokenIcon({ fillColor }) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.15 13.8198L14.11 16.8598" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.84998 13.8198H17.15" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.84998 10.1801L9.88998 7.14014" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.15 10.1802H6.84998" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function SquareIcon({ fillColor, ...rest }) {
    return (<svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.74 15.53L14.26 12L10.74 8.46997" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
};

function AddNotesIcon({ fillColor }) {
    return (<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.5399 3.01976L8.65988 10.8998C8.35988 11.1998 8.05988 11.7898 7.99988 12.2198L7.56988 15.2298C7.40988 16.3198 8.17988 17.0798 9.26988 16.9298L12.2799 16.4998C12.6999 16.4398 13.2899 16.1398 13.5999 15.8398L21.4799 7.95976C22.8399 6.59976 23.4799 5.01976 21.4799 3.01976C19.4799 1.01976 17.8999 1.65976 16.5399 3.01976Z" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.4102 4.1499C16.0802 6.5399 17.9502 8.4099 20.3502 9.0899" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

function ItemDetailsArrowIcon({ fillColor }) {
    return (<svg width="13" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 13L1 7L7 1" stroke={fillColor ? fillColor : "#8C8F91"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>)
}

export {
    HomeLogo,
    ItemsLogo,
    TokenIcon,
    UsersIcon,
    FeedbackIcon,
    ConfigurationIcon,
    QuickLinksIcon,
    TokensIcon,
    ItemsIcon,
    SearchIcon,
    FilterIcon,
    FAQsIcon,
    FAQsSelectedIcon,
    NotificationIcon,
    GenerateTokenIcon,
    TransferTokenIcon,
    SquareIcon,
    AddNotesIcon,
    ItemDetailsArrowIcon
}