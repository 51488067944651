import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { IMAGE_TYPES, getDefaultValues } from "../../utility-files/default-values/DefaultValues";
import { isArrayValue, isStringValue } from "../../utility-files/data-util/DataHandling";
import { getDateFormat, getTimeFormat } from "../../utility-files/date-util/DateHandling";
import { useSelector } from "react-redux";
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import ThemeAnchorLink from "../../qed-theme-module/ThemeAnchorLink";
import Image from "./Image";
import * as global from "../../constants/global"
import CustomLoader from "../../custom-ui/CustomLoader";
import { decryptData } from "../../utility-files/data-encryption-util/DataEncryption";


function ProductDetail({props}){
    const [pending, setPending] = useState(false);
    const[tokenIdDetails,setTokenIdDetails]=useState()
    const[tokenHistoryIdDetails,setTokenHistoryIdDetails]=useState()
    const[attachmentsIdList,setAttachmentsIdList]=useState()
    const[transferHistoryIdTimeLines,setTransferHistoryIdTimeLines]=useState()
    const [messageRes, setMessageRes] = useState(null);

    const param =useParams()
    const id=decryptData(param?.tokenId)
        const [itemConfig, setItemConfig] = useState(false);
        const themeData = useSelector((state) => state.reduxReducer.themeData);
    
        const elementStyle = {
            fontFamily: isStringValue(themeData?.fontName),
            color: isStringValue(themeData?.textColor)
        };
    
        const anchorLinkTheme = {
            color: isStringValue(themeData?.linkColor),
            fontFamily: isStringValue(themeData?.fontName),
            cursor: 'pointer'
        };
    
        
        const redirectToPage = (redirectUrl) => {
            if (/(http(s?)):\/\//i.test(redirectUrl)) {
                window.open(redirectUrl, '_blank')
            } else {
                window.open('http://' + redirectUrl, '_blank');
            };
        };
        
        const getTokenDetails = async () => {
            setPending(true);
            let variables = getDefaultValues().TOKEN_DETAILS_Id;
            variables.tokenid = isStringValue(id);
            let request = getRequestForApi(global.GET_BY_TOKENID, variables, methodType.POST);
            await callHttpRequest(request).then((response) => {
                if (response.status === 201) {
                    let data = response?.data?.response?.data;
                    if (data?.Notes?.length > 0) {
                        data.notes = data.Notes?.[data?.Notes?.length - 1].notes;
                        data.notesIndex = data?.Notes?.[data?.Notes?.length - 1]?.index;
                    } else {
                        data.notes = '';
                        data.notesIndex = '';
                    };
                    setTokenIdDetails(data);
                    listAttachment(isStringValue(data?.tokenid));
                    getTransferHistoryTimeLines({tokenid: isStringValue(id) });
                    if (data?.isCreator) {
                        if (data?.userSubscriptionStatus?.toLowerCase() === 'active') {
                            getTokenHistory(isStringValue(id));
                        } else {
                            setMessageRes(true);
                        };
                    } else {
                        getTokenHistory(isStringValue(id));
                    };
                };
                setPending(false);
            }).catch((err) => {
                console.log('err', err);
                setPending(false);
            });
        };
        
        const listAttachment = async () => {
            setPending(true);
            let variables = {
                tokenid: isStringValue(id),
                fileType: isStringValue('attachments')
            };
            let request = getRequestForApi(global.UPLOAD_LIST_BY_ID_ATTACHMENTS, variables, methodType.POST);
            await callHttpRequest(request).then((response) => {
                if (response?.status === 201) {
                    let res = response?.data?.response?.data;
                    res?.sort(function (a, b) { return a.ordBy - b.ordBy });
                    setAttachmentsIdList(isArrayValue(res));
                };
                setPending(false);
            }).catch((err) => {
                setPending(false);
                console.log("err", err)
            })
        };

        const getTransferHistoryTimeLines = async (variables) => {
            setPending(true);
            let request = getRequestForApi(global.GET_TOKEN_HISTORY_ID_TIMELINES, variables, methodType.POST);
            await callHttpRequest(request).then((response) => {
                if (response.status === 201) {
                    setTransferHistoryIdTimeLines(response.data.response.data);
                };
                setPending(false);
            }).catch((err) => {
                console.log('err', err);
                setPending(false);
            });
        };
        const getTokenHistory = async () => {
            setPending(true);
            let variables = {
                tokenid: id
            };
            let request = getRequestForApi(global.GET_TOKEN_ID_HISTORY, variables, methodType.POST);
            await callHttpRequest(request).then((response) => {
                if (response.status === 201) {
                    setTokenHistoryIdDetails(response?.data?.response?.data);
                };
                setPending(false);
            }).catch((err) => {
                console.log('err', err);
                setPending(false);
            });
        };
        
        const getItemConfig = async () => {
            setPending(true);
            let variables = {};
            variables.tokenid = isStringValue(id);
            let request = getRequestForApi(global.GET_ITEM_ID_CONFIG, variables, methodType.POST)
            await callHttpRequest(request).then((response) => {
                if (response?.status === 201) {
                    let responseData = response?.data?.response?.data;
                    responseData.push({
                        "labelName": "Token ID",
                        "keyName": "tokenId"
                    }, {
                        "labelName": "Serial No",
                        "keyName": "serialNo"
                    })
                    responseData = responseData?.sort(function (a, b) {
                        return a.labelName.localeCompare(b.labelName)
                    })
                    setItemConfig(responseData);
                };
                setPending(false);
            }).catch((err) => {
                console.log('err', err);
                setPending(false);
            });
        };
        
        useEffect(() => {
            getTokenDetails()
            getItemConfig();
       
        }, []);
        const tokensAttachmentsList = isArrayValue(attachmentsIdList)?.map((data, index) => {
            return <div className='d-flex flex-column' key={`attachmentsIdList${index}`}>
                <ThemeAnchorLink to={isStringValue(data?.fileUrl)}>
                    <Image src={data.fileName.includes('.pdf') ? IMAGE_TYPES.PDF_ICON : IMAGE_TYPES.XLXS} alt="" />
                    <p className='text-center py-1'>{isStringValue(data?.fileName).slice(0, 4) + isStringValue(data?.fileName).substring(isStringValue(data?.fileName).length - 4)}</p>
                </ThemeAnchorLink>
            </div>
        })
    
        const tokenHistoryColumns = <tr>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Transfer Date & Time</th>
        </tr>
    
    const tokenHistoryList = isArrayValue(tokenHistoryIdDetails)?.map((data, index) => {
        return <tr key={`history_${index}`}>
            <td>
                {data?.fromName}<br />
                {data?.fromWallet}
            </td>
            <td>
                {data?.toName}<br />
                {data?.toWallet}
            </td>
            <td>{getDateFormat(data?.tranferDate)} {getTimeFormat(data?.tranferDate)}</td>
        </tr>
    });
    const notesData = isStringValue(tokenIdDetails?.Notes[0]?.notes).split("\n")?.map((data, index) => {
        return isStringValue(data) !== '' && <span key={`notesData_${index}`}>{data}<br /></span>
    });
    
        const itemDetails = isArrayValue(itemConfig)?.map((data, index) => {
            return isStringValue(tokenIdDetails?.[data?.keyName]) && <li className="lh-lg d-flex gap-2 border-bottom-line" key={`itemDetails_${index}`}>
                <div className='w-25'><strong>
                    {isStringValue(data?.labelName)}:
                </strong>
                </div>
                {data?.keyName?.includes('dataLinks') ? <div className='w-75' style={anchorLinkTheme} title={tokenIdDetails?.[data?.keyName]} onClick={() => redirectToPage(tokenIdDetails?.[data?.keyName])}>
                    {tokenIdDetails?.[data?.keyName].substring(0, 50)}                 
                </div> :
                    <div className='w-75 scrollable-container'>
                        <span style={elementStyle}>
                            {isStringValue(tokenIdDetails?.[data?.keyName])}
                        </span>
                    </div>}
            </li>
        });
    
        return (
            <>
            {pending && <CustomLoader />}
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 style={elementStyle} className="modal-title" id="exampleModalCenterTitle">Token Details</h5>
                            {/* <button type="button" className="small btn-close"  data-bs-dismiss="modal"
                                aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                            <div className="detail noborder">
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className="row py-1">
                                            <div className="col-2">
                                                <Image src={isStringValue(tokenIdDetails?.Attachments?.[0]?.fileUrl) || IMAGE_TYPES.SKULL} className="item-image-radius img-fluid" alt="" style={{ height: "220px", width: "100%" }} />
                                            </div>
                                            <div className="col-10">
                                                <div className='d-flex view-detail-main-div'>
                                                    <div className='max-height-400'>
                                                        <ul className="px-2 lh-lg parallel-list">
                                                            {itemDetails}
                                                            {isStringValue(tokenIdDetails?.Notes[0]?.notes) && <li className="lh-lg d-flex gap-2"><div className='w-25'><strong>Notes:</strong></div><div style={elementStyle} className='notes-token-details w-75'>{notesData}</div></li>}
                                                        </ul>
                                                    </div>
                                                    <div className='timeline'>
                                                        <div className="history-tl-container">
                                                            <ul className="tl">
                                                                {isArrayValue(transferHistoryIdTimeLines)?.map((data, index) => {
                                                                    return <li className="tl-item" ng-repeat="item in retailer_history" key={`history_${index}`}>
                                                                        <div className="timestamp">
                                                                            {getDateFormat(data?.tranferDate)} {getTimeFormat(data?.tranferDate)}
                                                                        </div>
                                                                        <div className="item-title">
                                                                            {data?.fromName}
                                                                        </div>
                                                                        <div className="item-detail">
                                                                        {data?.fromWallet}

                                                                        </div>
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {isArrayValue(attachmentsIdList)?.length !== 0 && <div style={elementStyle} className="row image-content-row">
                                    <h3 className='heading-tt' style={elementStyle}>Attachments</h3>
                                    <div className="file-attachments d-flex gap-4 overflow-auto">
                                        {tokensAttachmentsList}
                                    </div>
                                </div>}
                                {isArrayValue(tokenHistoryIdDetails)?.length !== 0 && <div className="row py-1 px-3 image-content-row">
                                    <h3 className='heading-tt' style={elementStyle}>Transfer History</h3>
                                    <div className='table-token-detail table-token-detail-page px-0'>
                                        <table className="table table-striped table-bordered mb-0">
                                            <thead>
                                                {tokenHistoryColumns}
                                            </thead>
                                            <tbody>
                                                {tokenHistoryList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
        </>
        )
    }
export default ProductDetail
