export const IMAGE = "image/x-png,image/gif,image/jpeg,image/webp";
export const ATTACHMENTS = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
export const CSV = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
export const itemsData = [];
export const REDIRECT_URL = "http://localhost:3005/success/";
export const REDIRECT_CANCEL_URL = "http://localhost:3005/cancel";
// const redirect_URL = "http://businesslite.s3-website-us-west-1.amazonaws.com/succeess/";
export const APP_STORE_URL = process.env.REACT_APP_STORE_URL;
export const PLAY_STORE_URL = process.env.REACT_APP_PLAY_STORE_URL;

export const IMAGE_TYPES = {
    USER_LOGIN: require('../../assets/images/User.webp'),
    LOCK_PASS: require('../../assets/images/Lock.webp'),
    BRIEFCASE_SIX: require("../../assets/images/briefcase.png"),
    PHONE_NUMBER: require("../../assets/images/mobile.png"),
    GOOGLE_LOGO: require("../../assets/images/Google__G__Logo 1 (1).png"),
    ITEM_LOGO: require('../../assets/images/item-icons.png'),
    TOKEN: require('../../assets/images/token-icon.png'),
    BANNER_IMAGE: require('../../assets/images/headerbanner.svg').default,
    COIN_ITEM: require('../../assets/images/tokencoin-img.png'),
    GENERATED_TOKEN: require('../../assets/images/generated-token.png'),
    AVAILABLE_TOKEN: require('../../assets/images/available-token.png'),
    ARROW: require('../../assets/images/arrow-fwd.png'),
    HISTORY: require('../../assets/images/history_icon.svg').default,
    SELECTED_ARROW: require('../../assets/images/arrow-square-right-selected.svg').default,
    TOTAL_TOKEN: require('../../assets/images/total-token.png'),
    BUY_LOGO: require('../../assets/images/token-btn.png'),
    QUICKLINE_LOGO: require('../../assets/images/quick-link-logo.png'),
    GENERATE_LOGO: require('../../assets/images/generate-token-logo.png'),
    GENERATE_LOGO_AUTO: require('../../assets/images/auto.png'),
    TRANSFER_LOGO: require('../../assets/images/transfer-token.png'),
    CATEGORY_IMAGE: require("../../assets/images/item-logo.png"),
    CATEGORY_LOGO: require("../../assets/images/category.png"),
    // ITEM_ICON: require("../../assets/images/item-icon.png"),
    SKU_CODE: require("../../assets/images/SKU-code-icon.png"),
    UPC_CODE: require("../../assets/images/UPC-code-icon.png"),
    IDENTIFIER_IMAGE: require("../../assets/images/identifier-icon.png"),
    UPLOAD_IMAGE: require("../../assets/images/upload-icon.png"),
    SETTING_LOGO: require("../../assets/images/setting.png"),
    LOGOUT_LOGO: require("../../assets/images/logout.png"),
    INFORMATION_LOGO: require("../..//assets/images/Info.png"),
    LOAD_GIF: require("../../assets/images/load.gif"),
    CREATE_TOKEN: require("../../assets/images/createtoken.png"),
    WATCH: require("../../assets/images/watch.png"),
    BOX: require("../../assets/images/item-logo.png"),
    SERIAL_NO: require("../../assets/images/serialno.png"),
    IDENTIFIER: require("../../assets/images/identifier-icon.png"),
    TRANSFER: require("../../assets/images/transfer.png"),
    USER: require("../../assets/images/User.svg").default,
    ARROW_RIGHT: require("../../assets/images/arrow-square-right.svg").default,
    RIGHT_BANNER: require("../../assets/images/rightbannerlogin.png"),
    SCAN_BARCODE: require("../../assets/images/scan-barcode.svg").default,
    SKULL: require('../../assets/images/skeleton-img.svg').default,
    AUTO_GENERATE: require("../../assets/images/autogenerate.png"),
    STATUS_CHECK: require('../../assets/images/status-check.svg').default,
    RED_CROSS: require('../../assets/images/red-cross.svg').default,
    CAUTION_ICON: require('../../assets/images/caution-icon.svg').default,
    FILTER_IMAGE: require('../../assets/images/filter.svg').default,
    TOKENS_ICON2: require('../../assets/images/tokens-icon.svg').default,
    ACTIVE_TOKEN: require("../../assets/images/token-active.svg").default,
    PENCIL_IMAGE: require('../../assets/images/pencil.svg').default,
    ARRANGE_SQUARE: require('../../assets/images/arrange-square.svg').default,
    CLOSE_CIRCLE_IMAGE: require('../../assets/images/close-circle.svg').default,
    EDIT_BLUE_IMAGE: require('../../assets/images/edit-blue.svg').default,
    TRASH_RED: require('../../assets/images/trash-red.svg').default,
    TOKEN4_IMAGE: require('../../assets/images/token-with-bg.svg').default,
    REMOVE_BOX_IMAGE: require('../../assets/images/box-remove.svg').default,
    GENERATE_LOGO2: require('../../assets/images/gen-token-bg.svg').default,
    AVAILABLE_TOKEN2: require('../../assets/images/avl-token-bg.svg').default,
    TOKEN_NOT_IMAGE: require('../../assets/images/token-not.svg').default,
    TOKEN_WHITE_IMAGE: require('../../assets/images/token-white.svg').default,
    GEN_TOKEN_IMAGE: require('../../assets/images/gen-token.svg').default,
    FOLDER_IMAGE: require('../../assets/images/folder.svg').default,
    DELETE_IMAGE: require('../../assets/images/trash-red.svg').default,
    AUTO2_GENERATE: require('../../assets/images/auto-genated.svg').default,
    ADD_NOTE_IMAGE: require('../../assets/images/add-note.svg').default,
    CATEGORY2_IMAGE: require('../../assets/images/category.svg').default,
    USER1_IMAGE: require('../../assets/images/User_01.svg').default,
    NEW_CALENDER_IMAGE: require('../../assets/images/calendar-edit.svg').default,
    PDF_ICON: require('../../assets/images/pdf-img.svg').default,
    XLXS: require('../../assets/images/xls.svg').default,
    ADD_SIGN: require('../../assets/images/add.png'),
    MINUS_SIGN: require('../../assets/images/minus.png'),
    CUBE_BOX: require("../../assets/images/box.png"),
    LOGO_IMAGE: require("../../assets/images/logo.svg").default,
    NOTIFICATION_IMAGE: require("../../assets/images/notification.svg").default,
    MESSAGE_LOGO: require('../../assets/images/messages-2.svg').default,
    SEARCH_NEW_LOGO: require('../../assets/images/search-normal.svg').default,
    ITEM_NAME_LOGO2: require('../../assets/images/item-name.svg').default,
    UPC_NEW_CODE: require('../../assets/images/upc.svg').default,
    PASSWORD_CHECK_LOGO: require('../../assets/images/password-check.svg').default,
    OTHER_LOGO: require('../../assets/images/other.svg').default,
    BOX_IMAGE: require('../../assets/images/box.svg').default,
    PDF_IMAGE_LOGO: require('../../assets/images/pdf.svg').default,
    IMAGE1_LOGO: require('../../assets/images/watch/image1-watch.jpg'),
    MESSAGE_SELECTED_LOGO: require('../../assets/images/messages-selected.svg').default,
    NOTIFICATIONS_NEW: require('../../assets/images/notifications-new.svg').default,
    UAT: require('../../assets/images/UAT.svg').default,
    FEEDBACK: require('../../assets/images/feedback.png'),
    APP_STORE: require('../../assets/images/app store.png'),
    GOOGLE_PLAY: require('../../assets/images/google play.png'),
    USER_MANUAL: require('../../assets/images/user-manual.png'),
    PAYMENT_FAILED_IMAGE: require('../../assets/images/payment-failed.jpg'),
    CANDLE: require('../../assets/images/candle.svg').default,
    HOME_ICON: require('../../assets/images/home-icon.svg').default,
    ITEM_ICON: require('../../assets/images/menu-items.svg').default,
    TOKEN_ICON: require('../../assets/images/token.svg').default,
    USER_ICON: require('../../assets/images/user-square.svg').default,
    FEEDBACK_ICON: require('../../assets/images/feedback.svg').default,
    GREY_ICON: require('../../assets/images/greysettings.svg').default,
};

export const getDefaultValues = () => {
    let variables = {}
    variables.LOGIN_VARIABLES = {
        email: '',
        password: ''
    };
    variables.ALERT_OPTIONS = {
        showButton: false
    };
    variables.SIGNUP_VARIABLES = {
        email: "",
        firstName: "",
        contactNumber: '',
        companyName: '',
        systemIdentifier: "",
        password: "",
        confirmPassword: "",
        productCategory: ""
    };
    variables.CATEGORY_VARIABLES = {
        pageNo: 0,
        perPageItem: 0,
        name: "",
        Columns: [""]
    };
    variables.ITEM_LIST_VARIABLES = {
        pageNo: 0,
        perPageItem: 0,
        name: "",
        searchText: "",
        category: "",
        skuCode: "",
        upcCode: "",
        identifierCode: "",
        flag: 0,
        createDate: ""
    };
    variables.ADD_ITEM_LIST_VARIABLES = {
        name: '',
        description: "",
        category: "",
        skuCode: "",
        upcCode: "",
        identifierCode: "",
        dataLinks: "",
    };
    variables.UPLOAD_TYPE_VARIABLES = [
        {
            moduleId: "",
            module: "User",
            fileName: "",
            ordBy: 0,
            uploadType: 0,
            byteArray: ""
        }
    ];
    variables.GENERATE_TOKEN_VARIABLES = {
        itemId: 0,
        upcCode: "",
        serialNo: "",
        otherIdentifierCode: "",
        description: "",
        notes: "",
        expiryDate: ""
    }
    variables.TOKENS_LIST_VARIABLES = {
        pageNo: 0,
        perPageItem: 0,
        serialNumber: "",
        flag: 0,
        timeFlag: 0,
        createDate: "",
        searchText: "",
        index: "",
        owner: ""
    }
    variables.TOKEN_DETAILS_VARIABLES = {
        pageNo: 0,
        perPageItem: 0,
        index: "",
        tokenId:""
    }
    variables.TOKEN_DETAILS_Id = {
        
        tokenid:""
    }
    variables.TOKEN_TRANSFER = {
        email: "",
        index: [""]
    }

    return variables;
};

export const TOUR_STEPS = [
    {
        target: ".qed-logo",
        content: "This is our tour logo.",
        disableBeacon: true,
    },
    {
        target: ".my-sm-0",
        content: "click here to go to signup page",
    },
    {
        target: ".login",
        content: "Login from here 😉.",
    },
    {
        target: ".google-signin",
        content: "Sign in with Google 😉.",
    },
];

export const JOY_RIDE_STYLES = {
    tooltipContainer: {
        textAlign: "left"
    },
    buttonNext: {
        backgroundColor: "#005DFF"
    },
    buttonBack: {
        marginRight: 10,
        color: "#005DFF"
    },
    buttonClose: {
        display: 'none',
    }
};

export const LOCALE_INPUT = {
    last: "End tour",
    skip: "Skip"
};

export const PLACE_HOLDER_TEXT = {
    email: "Enter your email here",
    contactNumber: "Enter your contact number here",
    password: "Enter your password here",
    newPassword: 'Enter your new password',
    confirmPassword: 'Please confirm your new password',
    wallet_Name: "Enter your wallet name here",
    item_name: "Enter item name",
    serial_no: "Enter serial number",
    identifier: 'Enter a value',
    description: 'Enter description here',
    skuCode: 'Enter sku code here',
    upcCode: 'Enter upc code here',
    otp: 'Enter OTP here',
    CURRENT_PASSWORD: "Current Password",
    notes: 'Enter notes here',
};

export const DEFAULT_HOLDER_VALUES = {
    loading: 'Please wait...'
};

export const CONTENT_LIST = [
    {
        id: 0,
        action_name: 'Add Item',
        data_target: '#AddItem'
    },
    {
        id: 2,
        action_name: 'Generate Token',
        data_target: '#GenerateToken'
    },
    {
        id: 3,
        action_name: 'Generate Certificates',
        data_target: '#GenerateCertificates'
    },
    {
        id: 1,
        action_name: 'Transfer Token', 
        data_target: '#TransferToken'
    }
];


export const FILTER_LIST = [
    {
        id: '2',
        name: 'All'
    },
    {
        id: '0',
        name: 'Recently added'
    },
    {
        id: '1',
        name: 'Last Month'
    },
];

export const LABEL_TYPES = [
    {
        id: '',
        name: 'Select Label'
    },
    {
        id: 'text',
        name: 'Text'
    },
    {
        id: 'number',
        name: 'Number'
    },
    {
        id: 'password',
        name: 'Password'
    },
    {
        id: 'email',
        name: 'Email'
    },
    {
        id: 'checkbox',
        name: 'Checkbox'
    },
    {
        id: 'radio',
        name: 'Radio'
    }
];