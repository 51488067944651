import React, { Suspense, lazy } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { storageKey, getStorageData, storageType } from '../utility-files/storage-util/StorageHandling';
import { handleLogOut, path } from '../utility-files/helper-function/HelperFunction';
import CustomLoader from '../custom-ui/CustomLoader';
const Header = lazy(() => import('../common-pages/Header'));
const CommonLoginModule = lazy(() => import('../pages/user-module/CommonLoginModule'));

export const LoginSignupRoute = (props) => {
    const location = useLocation();
    const moduleHandler = () => {
        if (getStorageData(storageType, storageKey.TOKEN) || getStorageData(!storageType, storageKey.TOKEN)) {
            if (location?.pathname?.includes(path.ACCEPT_INVITE) || location?.pathname?.includes(path.ACCEPT_USER_INVITE)) {
                handleLogOut(true);
                return <Outlet {...props} />
            } else {
                return <Navigate replace to={path.HOME} />
            }
        } else {
            return <Outlet {...props} />
        };
    };
    return <>
        <Suspense fallback={<CustomLoader />}>
            <Header />
            {location.pathname === path.ABOUT_US ?
                moduleHandler()
                : <CommonLoginModule>
                    {moduleHandler()}
                </CommonLoginModule>}
        </Suspense>
    </>
}


export default LoginSignupRoute