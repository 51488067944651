import { utils, writeFileXLSX } from "xlsx";
import { callHttpRequest } from "../api-util/HttpRequest";
import { decryptData, jwtDecodeData } from "../data-encryption-util/DataEncryption";
import { isArrayValue, isObjectValue, isStringValue } from "../data-util/DataHandling";
import { getStorageData, storageKey, storageType } from "../storage-util/StorageHandling";
import { getCurrentDate } from "../date-util/DateHandling";
const removeList = ['event', 'fileUrl', 'uploadEventType', 'isValid'];
const imageKeysRemove = ['id', 'assetIndex', 'key', 'attribute1', 'attribute2', 'attribute3', 'isActive', 'createBy', 'createdAt', 'createdDateOnly', 'timestamp'];
const MIN_FILE_SIZE = 1024; // 1MB
const MAX_FILE_SIZE = 5120; // 5MB
const APP_NAME = "QED Business";
const UAT_URL = 'https://testqedvault.com';

const roleTypes = {
    ADMIN: 'admin',
    COMPANY_ADMIN: 'CompanyAdmin',
    MANUFACTURER: 'Manufacturer',
    MANUFACTURER_USER: 'manufactureruser',
    AGENT: 'agent',
    CUSTOMER: 'customer',
    User: 'User',
    TOKEN_CREATOR: 'TokenCreator',
    NOTE_CREATOR: 'NoteCreator'
};

const path = {
    LOGIN: '/login',
    HOME: '/',
    ITEMS: '/items',
    CONFIRM_PASSWORD: '/confirm_password/',
    ACCEPT_INVITE: '/accept_invite/',
    ACCEPT_USER_INVITE: '/accept_invite_company_user/',
    PRODUCT_DETAILS: '/productDetails/:tokenId',
    TOKENS: '/tokens',
    SIGNUP: '/signup',
    ADDITEM: '/AddItem',
    MINIADD: '/miniADD',
    ITEM_DETAILS: '/item-details',
    FILTER: '/CustomFilter',
    ERROR: '*',
    CHECKOUT_PLAN: '/checkout_plan',
    MFA: '/mfa',
    FAQ: '/faq',
    WELCOME: '/welcome',
    CHECKOUT: '/checkout',
    PAYMENTS: '/payments',
    SUCCESS: '/success',
    CANCEL: '/cancel',
    HISTORY: '/transaction-history',
    FEEDBACK: '/feedback',
    USERS: '/users',
    ABOUT_US: '/about',
    INVITE_ACCEPT_SUCCESS: "/invite-accept-success",
    USER_MANUAL: 'https://qedlitevault.s3.us-west-1.amazonaws.com/UserManual/QEDBusinessUserManual.pdf',
    PRIVACY_POLICY: '/privacy_policy',
    TERMS: '/terms',
    TERMS_MOBILE: '/terms_mobile',
    DELETEACCOUNT: '/delete-account',
    CONFIRM_DELETE_ACCOUNT: "/delete-account-confirm/",
    ITEM_CONFIGURATION: "/ItemConfiguration",
    QED_THEME_CONFIGURATION: "/QED-theme-configuration",
};

const PATH_SLUG = {
    MFA: '/:id/:rem',
    CONFIRM_PASSWORD: ':email',
    ACCEPT_INVITE: ":index/:inviteCode/:email",
    ITEM_DETAILS: '/:index/:id',
    PAYMENTS: '/:secret',
    ACCEPT_USER_INVITE: ':inviteCode/:email/:company',
    CONFIRM_DELETE_ACCOUNT: ':email',
};

const alertTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error'
};

const alertMessages = {
    LOGGEDIN: 'User logged in successfully.',
    SIGNUP: 'User signed up successfully.',
    ENTER_CREDENTIALS: 'Please enter your credentials',
    ENTER_NAME: 'Please enter item name',
    ENTER_COMPANYNAME: 'Please enter your Company name ',
    ENTER_NUMBER: 'Please enter your phone number',
    ENTER_EMAIL: 'Please enter your email',
    ENTER_PASSWORD: 'Please enter your password',
    ENTER_CONFIRMPASSWORD: "Password  and confirm password should be same",
    COPY_SUCCESSFUL: 'Copying to clipboard was successful.',
    UPDATE_NAME: 'User name updated successfully.',
    UPDATE_WALLET_NAME: 'User wallet name updated successfully.',
    UPDATE_PASSWORD: 'User password updated successfully.',
    ADD_ITEM_SUCCESS: 'Item created successfully.',
    ENABLE_AUTH: "Authentication enabled successfully.",
    DISABLE_AUTH: "Authentication disabled successfully.",
    TOKEN: "Token generated successfully.",
    UPDATE_USER_EMAIL: 'User email updated successfully.',
    UPDATE_USER_NUMBER: 'User number updated successfully.',
    UPDATE_ZIPCODE: 'Zip code updated successfully.',
    FILTER: "Item Filtered successfully.",
    Category: 'Please enter category',
    DESCRIPTION: "Numbers doest not allow",
    ENTER_SERIAL_NUMBER: "Please enter a valid Serial number",
    ENTER_SKUCODE: "Please enter a valid SKU code and Special letter not Allowed",
    ENTER_UPCCODE: "Please enter a valid UPC code and Special letter not Allowed",
    ENTER_other: "Please enter a valid identifire code and Special letter not Allowed",
    OTP_SUCCESS: 'OTP resent successfully.',
    ENTER_TRANSFERSUCCESS: "Token(s) transferred successfully.",
    TRANSFER_SUCCESS: "Token transferred successfully.",
    RESETPASSWORD: "Reset password link sent on your email.",
    FORGOTPASSD_MESSAGE: "Password reset successfully.",
    INVITE_MESSAGE: "Invite link sent on entered email",
    ACCEPT_MESSAGE: "Success",
    VALID_URL: "Please enter a valid url",
    UPDATE_ITEM: "Item updated successfully.",
    PAYMENT_SUCCESS: "Payment successfull.",
    NOTES_ADD_SUCCESS: 'Notes added successfully.',
    NOTES_UPDATE_SUCCESS: 'Notes updated successfully.',
    MAX_LIMIT: 'File size is greater than maximum limit of 1 MB',
    INVITE_ACCEPT_SUCCESS: "Invitation accepted successfully.",
    DELETE_ACCOUNT: " Your account deleted successfully. ",
    ITEM_CONFIG_SUCCESS: 'configuration added successfully',
    ITEM_CONFIG_UPDATE_SUCCESS: 'configuration updated successfully',
    ITEM_CONFIG_DELETE_SUCCESS: 'configuration deleted successfully',
    ITEMS_ADD_ERROR: 'Only 15 item configurations can be added',
    ADD_THEME_SUCCESS: 'Theme added successfully',
    UPDATE_THEME_SUCCESS: 'Theme updated successfully',
    DELETE_THEME_SUCCESS: 'Theme deleted successfully',
    APPLY_THEME_SUCCESS: 'Theme applied successfully',
    USER_ROLE_UPDATE_SUCCESS: 'User role updated successfully'
};

const errorMessages = {
    ENTER_VALID_EMAIL: "Please enter a valid email",
    ENTER_NAME: "Please enter your name",
    PASSWORD: 'Password length must be eight or more characters and must contain special characters.',
    SELECT_ITEM: "Please select the item"
};

const modelType = {
    ADD_ITEM: "ADD_ITEM",
};

const copyText = (text) => {
    navigator.clipboard.writeText(text).then(function () {
    }, function (err) {
        console.error(err);
    });
};

const getApiResponse = async (request) => {
    let res = null;
    await callHttpRequest(request).then(response => {
        if (response?.status === 201) {
            res = response?.data;
        }
    }).catch((err) => {
        res = err;
    });
    return res;
};

const userDetails = () => {
    if (isObjectValue(getStorageData(storageType, storageKey.TOKEN) ? getStorageData(storageType, storageKey.TOKEN) : getStorageData(!storageType, storageKey.TOKEN))) {
        return jwtDecodeData(getStorageData(storageType, storageKey.TOKEN) ? getStorageData(storageType, storageKey.TOKEN) : getStorageData(!storageType, storageKey.TOKEN));
    }
};

function accessManager(role) {
    let access_type = {};
    if (role === roleTypes.NOTE_CREATOR) {
        access_type.notesAccess = true;
        access_type.createTokensAccess = false;
        access_type.createCertificatesAccess = false;
        access_type.createItemAccess = false;
        access_type.transferAccess = true;
        access_type.subscribeAccess = false;
    } else if (role === roleTypes.TOKEN_CREATOR) {
        access_type.notesAccess = false;
        access_type.createTokensAccess = true;
        access_type.createItemAccess = false;
        access_type.createCertificatesAccess = false;
        access_type.transferAccess = true;
        access_type.subscribeAccess = false;
    } else if (role === roleTypes.COMPANY_ADMIN) {
        access_type.notesAccess = true;
        access_type.createTokensAccess = true;
        access_type.createCertificatesAccess = true;
        access_type.createItemAccess = true;
        access_type.transferAccess = true;
        access_type.subscribeAccess = true;
    } else {
        access_type.notesAccess = false;
        access_type.createTokensAccess = false;
        access_type.createCertificatesAccess = false;
        access_type.createItemAccess = false;
        access_type.transferAccess = false;
        access_type.subscribeAccess = false;
    };
    return access_type;
};

const getBuffer = (buffer) => {
    let bufferNew = buffer?.split(",");
    if (bufferNew.length >= 1) {
        bufferNew = bufferNew[1];
    }
    return bufferNew;
};

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            let baseURL = fileReader.result
            resolve(baseURL);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

const handleFileUpload2 = async (event, uploadType, itemIndex, attachmentsList) => {
    let uploadFilesData = [];
    if (attachmentsList && attachmentsList?.length !== 0) {
        attachmentsList.forEach(element => {
            element.isValid = true;
        });
        uploadFilesData = attachmentsList;
    };
    let uploadDataType = {};
    uploadDataType.event = event;
    uploadDataType.byteArray = '';
    uploadDataType.fileName = '';
    uploadDataType.fileUrl = '';
    uploadDataType.uploadType = FILE_HANDLER.ADD;
    uploadDataType.uploadEventType = uploadType;
    if (uploadType === 'profile') {
        uploadDataType.moduleId = userDetails()?.id;
        uploadDataType.module = "User";
        uploadDataType.ordBy = 0;
    } else {
        uploadDataType.itemIndex = itemIndex;
        uploadDataType.fileType = uploadType;
        uploadDataType.ordBy = 0;
    };
    let filesData = uploadDataType.event.target.files;
    if (uploadDataType.uploadEventType === 'profile') {
        let buffer = await convertBase64(filesData[0]);
        return [{
            ...uploadDataType,
            fileName: filesData[0].name,
            byteArray: getBuffer(buffer),
            fileUrl: buffer,
            isValid: checkValidUpload(filesData[0].name, uploadDataType.uploadEventType)
        }];
    } else {
        for (let index = 0; index < filesData?.length; index++) {
            let image = filesData[index];
            let fileName = filesData[index].name;
            let buffer = await convertBase64(image);
            uploadFilesData.push({
                ...uploadDataType,
                byteArray: getBuffer(buffer),
                fileUrl: buffer,
                fileName: fileName,
                isValid: checkValidUpload(fileName, uploadDataType.uploadEventType)
            });
        }
        uploadFilesData.forEach((element, index) => {
            element.ordBy = index;
            element.uploadType = element.uploadType ? element.uploadType : FILE_HANDLER.ADD;
            imageKeysRemove.forEach(data => {
                delete element[data];
            });
        });
        return uploadFilesData;
    };
};

const handleLogOut = (reset) => {
    localStorage.clear();
    sessionStorage.clear();
    if (!reset) {
        window.location.href = path.HOME;
    }
};

const downloadXMLfile = (data, filename) => {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const TOKENS_JSON = [
    {
        // upcCode: "TEST01",
        serialNo: "TEST01",
        // otherIdentifierCode: "TEST01",
        // description: "Test item one",
        notes: "TEST01",
    },
    {
        // upcCode: "",
        serialNo: "TEST001",
        // otherIdentifierCode: "",
        // description: "Test item two",
        notes: "",
    },
    {
        // upcCode: "TEST03",
        serialNo: "TEST03",
        // otherIdentifierCode: "TEST03",
        // description: "Test item three",
        notes: "TEST03",
    },
    {
        // upcCode: "TEST001",
        serialNo: "",
        // otherIdentifierCode: "",
        // description: "Test item four",
        notes: "",
    }
];

const navbar_links = [
    {
        id: 1,
        name: 'Tokens'
    },
    {
        id: 5,
        name: 'Certificates'
    },
    {
        id: 4,
        name: 'Transferred'
    },
    {
        id: 3,
        name: 'Discarded'
    },

    {
        id: 2,
        name: 'Unclaimed'
    },
    {
        id: 0,
        name: 'All'
    }
];

const jsonToXLSXFile = async (array, name) => {
    let jsonArray = [];
    let fileName = '';
    if (isArrayValue(array)?.length !== 0 && isStringValue(name) !== '') {
        jsonArray = isArrayValue(array);
        fileName = isStringValue(name)
    } else {
        jsonArray = TOKENS_JSON;
        fileName = `QED_lite_token${getCurrentDate()}.xlsx`
    };
    const ws = utils.json_to_sheet(jsonArray);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "QEDValt");
    writeFileXLSX(wb, fileName);
};

const FILE_HANDLER = {
    ADD: 0,
    UPDATE: 1,
    DELETE: 2
};

const checkValidUpload = (fileName, uploadDataType) => {
    var splitString = fileName.split(".");
    var fileExt = splitString[splitString.length - 1];
    let isValid = false;
    if (uploadDataType.toLowerCase() === 'profile' || uploadDataType.toLowerCase() === 'file') {
        if (fileExt.toLowerCase() === "jpeg" || fileExt.toLowerCase() === 'jfif' || fileExt.toLowerCase() === "jpg" || fileExt.toLowerCase() === "gif" || fileExt.toLowerCase() === "png" || fileExt.toLowerCase() === "webp") {
            isValid = true;
        };
    } else {
        if (fileExt.toLowerCase() === "pdf" || fileExt.toLowerCase() === "doc" || fileExt.toLowerCase() === "docx" || fileExt.toLowerCase() === "xls" || fileExt.toLowerCase() === "xlsx" || fileExt.toLowerCase() === "txt") {
            isValid = true;
        };
    };
    return isValid;
}

const getUuid = () => {
    let firstHalf = Date.now().toString(36);
    let secondHalf = Math.random().toString(36).substring(2, 9);
    return firstHalf + secondHalf
};

const getHostUrl = () => {
    return window.location.origin
};

function openWindow(url) {
    window.open(isStringValue(url), "", "width=700,height=500");
};

const userLocation = () => {
    let coords;
    if (getStorageData(storageType, storageKey.USER_LOACTION)) {
        coords = JSON.parse(decryptData(getStorageData(storageType, storageKey.USER_LOACTION)));
    };
    return coords;
};

const Header_Elements = [
    {
        id: 1,
        path: path.HOME,
        tabName: 'Home'
    },
    {
        id: 2,
        path: path.ITEMS,
        tabName: 'Items'
    },
    {
        id: 3,
        path: path.TOKENS,
        tabName: 'Tokens'
    },
    {
        id: 4,
        path: path.USERS,
        tabName: 'Users'
    },
    {
        id: 5,
        path: path.FEEDBACK,
        tabName: 'Feedback'
    },
    {
        id: 6,
        path: path.ITEM_CONFIGURATION,
        tabName: 'Item configuration'
    }
];

const users_roles = [
    {
        id: roleTypes.User,
        name: roleTypes.User.replace(/([A-Z])/g, ' $1').trim()
    },
    {
        id: roleTypes.TOKEN_CREATOR,
        name: roleTypes.TOKEN_CREATOR.replace(/([A-Z])/g, ' $1').trim()
    },
    {
        id: roleTypes.NOTE_CREATOR,
        name: roleTypes.NOTE_CREATOR.replace(/([A-Z])/g, ' $1').trim()
    },
    {
        id: roleTypes.COMPANY_ADMIN,
        name: roleTypes.COMPANY_ADMIN.replace(/([A-Z])/g, ' $1').trim()
    }
];

export {
    removeList,
    imageKeysRemove,
    MIN_FILE_SIZE,
    MAX_FILE_SIZE,
    APP_NAME,
    UAT_URL,
    roleTypes,
    path,
    PATH_SLUG,
    alertTypes,
    alertMessages,
    errorMessages,
    modelType,
    copyText,
    getApiResponse,
    userDetails,
    getBuffer,
    convertBase64,
    handleFileUpload2,
    handleLogOut,
    downloadXMLfile,
    TOKENS_JSON,
    jsonToXLSXFile,
    FILE_HANDLER,
    checkValidUpload,
    getUuid,
    getHostUrl,
    openWindow,
    userLocation,
    Header_Elements,
    navbar_links,
    accessManager,
    users_roles
};