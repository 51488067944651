import { createState } from "@hookstate/core";

const ItemDetailsState = createState({
    item_Details: {}
});

const ItemRefetchState = createState({
    items_refetch: false
});

const userRefetchState = createState({
    user_refetch: false
});

const ShowAddItemModel = createState({
    showAddItemModel: false
});

export {
    ItemDetailsState,
    ItemRefetchState,
    userRefetchState,
    ShowAddItemModel
}