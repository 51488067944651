import React, { useEffect, useState } from 'react';
import { IMAGE_TYPES } from '../utility-files/default-values/DefaultValues';
import Image from '../pages/common-module/Image';
import CustomInput from '../custom-input-fields/CustomInput';
import * as global from '../constants/global'
import { getRequestForApi } from '../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../utility-files/api-util/HttpRequest';
import { useAlert } from 'react-alert';
import { alertMessages, alertTypes, userDetails } from '../utility-files/helper-function/HelperFunction';
import { isIntegerValue, isStringValue } from '../utility-files/data-util/DataHandling';
import ThemeButton from '../qed-theme-module/ThemeButton';
import { useSelector } from 'react-redux';

function AddUpdateTheme(props) {
    const { showModal, setShowModal, onSuccess, applyTheme } = props;
    const themeDataN = useSelector((state) => state.reduxReducer.themeData);

    const elementStyle = {
        fontFamily: isStringValue(themeDataN?.fontName),
        color: isStringValue(themeDataN?.textColor)
    };

    const alert = useAlert();
    const [pending, setPending] = useState(false);
    const [themeData, setThemeData] = useState(null);

    useEffect(() => {
        if (showModal?.id) {
            setThemeData(showModal);
        } else {
            setThemeData({
                "name": "",
                "backgroundColor": "#e6e6e6",
                "textColor": "#000000",
                "buttonBackgroundColor": "#005dff",
                "buttonTextColor": "#ffffff",
                "linkColor": "#8c8f90",
                "fontName": "Source Sans 3",
                "contentBackgroundColor": "#ffffff",
                "orgId": ""
            })
        };
    }, [showModal]);

    const FONT_FAMILY = [
        {
            id: 'Source Sans 3',
            name: 'Source Sans 3'
        },
        {
            id: 'Tahoma, sans-serif',
            name: 'Tahoma, sans-serif'
        },
        {
            id: 'Garamond, serif',
            name: 'Garamond, serif'
        },
        {
            id: 'Arial, sans-serif',
            name: 'Arial, sans-serif'
        }
    ];

    const addTheme = async () => {
        setPending(true);
        let variables = {}
        variables.name = isStringValue(themeData['name']);
        variables.backgroundColor = isStringValue(themeData['backgroundColor']);
        variables.textColor = isStringValue(themeData['textColor']);
        variables.buttonBackgroundColor = isStringValue(themeData['buttonBackgroundColor']);
        variables.buttonTextColor = isStringValue(themeData['buttonTextColor']);
        variables.linkColor = isStringValue(themeData['linkColor']);
        variables.fontName = isStringValue(themeData['fontName']);
        variables.orgId = isStringValue(userDetails()['orgId']);
        variables.contentBackgroundColor = isStringValue(themeData['contentBackgroundColor']);
        let request = getRequestForApi(global.ADD_THEME, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response.status === 201) {
                if (response?.data?.error === 201) {
                    alert.show(alertMessages.ADD_THEME_SUCCESS, { type: alertTypes.SUCCESS });
                    setShowModal(false);
                    setThemeData(null);
                    onSuccess();
                };
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const updateTheme = async () => {
        setPending(true);
        let variables = {}
        variables.id = isIntegerValue(themeData['id']);
        variables.name = isStringValue(themeData['name']);
        variables.backgroundColor = isStringValue(themeData['backgroundColor']);
        variables.textColor = isStringValue(themeData['textColor']);
        variables.buttonBackgroundColor = isStringValue(themeData['buttonBackgroundColor']);
        variables.buttonTextColor = isStringValue(themeData['buttonTextColor']);
        variables.linkColor = isStringValue(themeData['linkColor']);
        variables.fontName = isStringValue(themeData['fontName']);
        variables.contentBackgroundColor = isStringValue(themeData['contentBackgroundColor']);
        let request = getRequestForApi(global.UPDATE_THEME, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response.status === 201) {
                if (response?.data?.error === 201) {
                    alert.show(alertMessages.UPDATE_THEME_SUCCESS, { type: alertTypes.SUCCESS });
                    console.log(response?.data?.response?.data?.id)
                    const element = response?.data?.response?.data
                    if (themeDataN['id'] === element['id']) {
                        applyTheme(element);
                    };
                    setShowModal(false);
                    setThemeData(null);
                    onSuccess();
                };
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const handleSubmit = () => {
        if (themeData?.id) {
            updateTheme();
        } else {
            addTheme();
        };
    };

    return (
        <div className={`modal ${showModal ? 'show pageoverlay-model' : 'fade'}`} id="AddUpdateTheme" tabIndex="-1" aria-labelledby="addItemModel" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className='modal-dialog modal-dialog-scrollable'>
                <div className='modal-content'>
                    <div className="modal-header">
                        <div className="modal-head d-flex gap-2">
                            <Image src={IMAGE_TYPES.CATEGORY_IMAGE} alt />
                            <h5 style={elementStyle} className="modal-title" id="exampleModalLabel">{themeData?.id ? 'Update Theme' : 'Create Theme'}</h5>
                        </div>
                        <div className="close">
                            <button type="button" className="small btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    setShowModal(false);
                                    setThemeData(null);
                                }}
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="modal-input">
                            <label htmlFor="theme name" style={elementStyle}>Theme Name<span className="required">*</span></label>
                            <div className="input-box d-flex"  >
                                <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                <CustomInput
                                    classType={`form-control`}
                                    fieldType={'text'}
                                    fieldId={'#theme'}
                                    setFieldData={setThemeData}
                                    filedData={themeData}
                                    keyName={'name'}
                                    maxCharacterLength={'50'}
                                    placeholderType={`Enter theme name`}
                                    style={elementStyle}
                                />
                            </div>
                        </div>
                        <div className="modal-input">
                            <label htmlFor="theme color" style={elementStyle}>Text Color</label>
                            <div className="input-box d-flex"  >
                                <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                <CustomInput
                                    classType={`form-control`}
                                    fieldType={'color'}
                                    fieldId={'#theme'}
                                    setFieldData={setThemeData}
                                    filedData={themeData}
                                    keyName={'textColor'}
                                    maxCharacterLength={'50'}
                                    placeholderType={`Enter theme name`}
                                />
                            </div>
                        </div>
                        <div className="modal-input">
                            <label htmlFor="Background color" style={elementStyle}>Background Color</label>
                            <div className="input-box d-flex"  >
                                <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                <CustomInput
                                    classType={`form-control`}
                                    fieldType={'color'}
                                    fieldId={'#theme'}
                                    setFieldData={setThemeData}
                                    filedData={themeData}
                                    keyName={'backgroundColor'}
                                    maxCharacterLength={'50'}
                                    placeholderType={`Enter theme name`}
                                />
                            </div>
                        </div>
                        <div className="modal-input">
                            <label htmlFor="modal background color" style={elementStyle}>Modal & Table Background Color</label>
                            <div className="input-box d-flex"  >
                                <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                <CustomInput
                                    classType={`form-control`}
                                    fieldType={'color'}
                                    fieldId={'#theme'}
                                    setFieldData={setThemeData}
                                    filedData={themeData}
                                    keyName={'contentBackgroundColor'}
                                    maxCharacterLength={'50'}
                                    placeholderType={`Enter theme name`}
                                />
                            </div>
                        </div>
                        <div className="modal-input">
                            <label htmlFor="button background color" style={elementStyle}>Button Background Color</label>
                            <div className="input-box d-flex"  >
                                <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                <CustomInput
                                    classType={`form-control`}
                                    fieldType={'color'}
                                    fieldId={'#theme'}
                                    setFieldData={setThemeData}
                                    filedData={themeData}
                                    keyName={'buttonBackgroundColor'}
                                    maxCharacterLength={'50'}
                                    placeholderType={`Enter theme name`}
                                />
                            </div>
                        </div>
                        <div className="modal-input">
                            <label htmlFor="button text color" style={elementStyle}>Button Text Color</label>
                            <div className="input-box d-flex"  >
                                <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                <CustomInput
                                    classType={`form-control`}
                                    fieldType={'color'}
                                    fieldId={'#theme'}
                                    setFieldData={setThemeData}
                                    filedData={themeData}
                                    keyName={'buttonTextColor'}
                                    maxCharacterLength={'50'}
                                    placeholderType={`Enter theme name`}
                                />
                            </div>
                        </div>
                        <div className="modal-input">
                            <label htmlFor="link color" style={elementStyle}>Link Color</label>
                            <div className="input-box d-flex"  >
                                <Image src={IMAGE_TYPES.CATEGORY_LOGO} alt />
                                <CustomInput
                                    classType={`form-control`}
                                    fieldType={'color'}
                                    fieldId={'#theme'}
                                    setFieldData={setThemeData}
                                    filedData={themeData}
                                    keyName={'linkColor'}
                                    maxCharacterLength={'50'}
                                    placeholderType={`Enter theme name`}
                                />
                            </div>
                        </div>
                        <div className="modal-input">
                            <label htmlFor="font name" style={elementStyle}>Select a Font</label>
                            <div className="input-box d-flex"  >
                                <CustomInput
                                    classType={`form-control form-select`}
                                    fieldType={'select'}
                                    selectList={FONT_FAMILY}
                                    fieldId={'#theme'}
                                    setFieldData={setThemeData}
                                    filedData={themeData}
                                    keyName={'fontName'}
                                    style={elementStyle}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="col-5 p-0 d-flex justify-content-end gap-1" >
                            <ThemeButton type="button" className={`btn btn-primary ${isStringValue(themeData?.name) ? "" : "disable-button"}`} onClick={() => handleSubmit()}>{themeData?.id ? 'Update Theme' : 'Create Theme'}</ThemeButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUpdateTheme;