const $ = window.jQuery = require('jquery')

// $(document).ready(function () {
$(document).on('click', ".filter_btn", function () {
  $(".searchFilter").show();
  $(".pageoverlay").show();
});

$(document).on('click', ".applyButton", function () {
  $(".searchFilter").hide();
  $(".pageoverlay").hide();
});

$(document).on('click', ".toggle_menu", function () {
  $(".menuBox").slideToggle();
});