import { createSlice } from '@reduxjs/toolkit'

export const reduxSlice = createSlice({
    name: 'theme',
    initialState: {
        themeData: {},
        userData: {},
        walletData: {},
        userLocation: {}
    },
    reducers: {
        currentTheme: (state, action) => {
            state.themeData = action.payload
        },
        userData: (state, action) => {
            state.userData = action.payload
        },
        walletData: (state, action) => {
            state.walletData = action.payload
        },
        userLocation: (state, action) => {
            state.userLocation = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { currentTheme, userData, walletData, userLocation } = reduxSlice.actions

export default reduxSlice.reducer