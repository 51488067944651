import React from 'react'

function NoRecord({ message }) {
    return (
        <div className='no-record-div'>
            <h5 className='d-inline'>{message || 'There are no records to display'}</h5>
        </div>
    )
}

export default NoRecord