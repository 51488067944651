import React, { useEffect, useState } from 'react'
import CustomInput from '../custom-input-fields/CustomInput'
import CustomLoader from '../custom-ui/CustomLoader';
import { DEFAULT_HOLDER_VALUES } from '../utility-files/default-values/DefaultValues';
import { isStringValue } from '../utility-files/data-util/DataHandling';
import { getRequestForApi } from '../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../utility-files/api-util/HttpRequest';
import { useAlert } from 'react-alert';
import { alertTypes, roleTypes } from '../utility-files/helper-function/HelperFunction';
import * as global from "../../src/constants/global"
import ThemeButton from '../qed-theme-module/ThemeButton';
import { useSelector } from 'react-redux';

function InviteUser({ isShowModel, setIsShowModel, onSuccess }) {
    const alert = useAlert();
    const [userData, setUserData] = useState(null);
    const [pending, setPending] = useState(false);
    const [confirmationModal, setConfirmationModel] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const themeData = useSelector((state) => state.reduxReducer.themeData);

    const elementStyle = {
        fontFamily: isStringValue(themeData?.fontName),
        color: isStringValue(themeData?.textColor)
    };
    
    useEffect(() => {
        if (isShowModel) {
            getUserDetails();
        };
    }, [isShowModel]);

    const getUserDetails = async () => {
        setPending(true);
        let variables = undefined;
        let request = getRequestForApi(global.GET_USER_DETAILS, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                setUserInfo(response.data);
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const sendInvite = async () => {
        setPending(true);
        let variables = {};
        variables.email = isStringValue(userData?.email);
        variables.type = roleTypes.MANUFACTURER;
        let request = getRequestForApi(global.SEND_INVITE, variables, methodType.POST);
        await callHttpRequest(request).then(response => {
            if (response?.status === 201) {
                alert.show(response?.data?.message, { type: alertTypes.SUCCESS });
                onSuccess();
                setUserData(null);
            } else {
                alert.show(response?.data?.response?.data, { type: alertTypes.ERROR });
            };
            setPending(false);
            setConfirmationModel(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            setConfirmationModel(false);
            console.log("err", err);
        });
    };

    const handleClose = () => {
        setIsShowModel(!isShowModel);
        setUserData(null);
    };

    return (
        <>
            {pending && <CustomLoader />}
            <div className={`modal ${isShowModel ? 'show page-over' : 'fade'}`} id="InviteUser" tabIndex="-1" role="dialog" aria-labelledby="InviteUser" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 style={elementStyle} className="modal-title" id="exampleModalCenterTitle">Invite Other Admins</h5>
                            <button type="button" className="small btn-close" data-bs-dismiss="modal"
                                aria-label="Close" onClick={() => handleClose()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="input-box">
                                <CustomInput fieldType={"email"}
                                    classType={"form-control"}
                                    setFieldData={setUserData}
                                    filedData={userData}
                                    fieldId={'#i_email'}
                                    keyName={'email'}
                                    placeholderType={"Enter user's email"}
                                    isrequired="true"
                                    autoComplete="off"
                                    style={elementStyle}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <ThemeButton type="button" className={`btn btn-primary ${!userData?.isEmailValid ? 'disable-button' : ''}`} onClick={() => { setIsShowModel(false); setConfirmationModel(true); }}>{pending ? DEFAULT_HOLDER_VALUES.loading : 'Invite'}</ThemeButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal ${confirmationModal ? 'show page-over' : 'fade'}`} id="InviteUser" tabIndex="-1" role="dialog" aria-labelledby="InviteUser" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal-content-size">
                        <div className="modal-header">
                            <p style={elementStyle} className="modal-content-font">Warning: Users invited will have rights to generate tokens on behalf of {isStringValue(userInfo?.companyName)}</p>
                        </div>
                        <div className="modal-footer">
                            <ThemeButton type="button" className={`btn btn-primary ${!userData?.isEmailValid ? 'disable-button' : ''}`} onClick={() => sendInvite()}>{pending ? DEFAULT_HOLDER_VALUES.loading : 'Yes'}</ThemeButton>
                            <ThemeButton type="button" className={`btn btn-primary ${!userData?.isEmailValid ? 'disable-button' : ''}`} onClick={() => { setConfirmationModel(false); setIsShowModel(true); }}>{pending ? DEFAULT_HOLDER_VALUES.loading : 'No'}</ThemeButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InviteUser
