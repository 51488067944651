import React from 'react'
import { useSelector } from 'react-redux';
import { isStringValue } from '../utility-files/data-util/DataHandling';

function ThemeButton({ children, ...rest }) {
    const themeData = useSelector((state) => state.reduxReducer.themeData);
    const buttonCustomTheme = {
        background: isStringValue(themeData.buttonBackgroundColor),
        fontFamily: isStringValue(themeData.fontName),
        color: isStringValue(themeData.buttonTextColor)
    };
    return (
        <button style={buttonCustomTheme} {...rest} >{children}</button>
    )
}

export default ThemeButton
