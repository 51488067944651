import React, { useEffect } from 'react'
import CustomLoader from '../../custom-ui/CustomLoader';

const Play_Store_URL = 'https://play.google.com/store/apps/details?id=com.qed_lite';
const App_Store_URL = 'https://apps.apple.com/in/app/qedvault/id6451490560'

function DetectOS() {
    
    useEffect(() => {
        const platform = window.navigator?.userAgentData?.platform || window.navigator.platform, iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        if (iosPlatforms.indexOf(platform) !== -1) {
            window.location.href = App_Store_URL;
        } else {
            window.location.href = Play_Store_URL;
        }
    }, []);

    return (
        <><CustomLoader /></>
    )
}

export default DetectOS