import React, { useEffect, useState } from 'react'
import CustomInput from '../custom-input-fields/CustomInput'
import CustomLoader from '../custom-ui/CustomLoader';
import { isArrayValue, isBooleanValue, isIntegerValue, isStringValue } from '../utility-files/data-util/DataHandling';
import { getRequestForApi } from '../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../utility-files/api-util/HttpRequest';
import { useAlert } from 'react-alert';
import { alertMessages, alertTypes } from '../utility-files/helper-function/HelperFunction';
import * as global from "../../src/constants/global";
import CommonTable from '../pages/common-module/CommonTable';
import DeleteConfig from './DeleteConfig';
import NoRecord from '../pages/common-module/NoRecord';
import ThemeButton from '../qed-theme-module/ThemeButton';
import { useSelector } from 'react-redux';

function AddItemConfig({ isShowModel, setIsShowModel, configurationId, setConfigurationId, onSuccess }) {
    const alert = useAlert();
    const [itemConfig, setItemConfig] = useState(null);
    const [saveItemConfig, setSaveItemConfig] = useState(false);
    const [pending, setPending] = useState(false);
    const [itemList, SetItemList] = useState(null);
    const [isEditItem, setIsEditItem] = useState(null);
    const [deleteItemConfig, setDeleteItemConfig] = useState(null);
    const themeData = useSelector((state) => state.reduxReducer.themeData);

    const elementStyle = {
        fontFamily: isStringValue(themeData?.fontName),
        color: isStringValue(themeData?.textColor)
    };

    useEffect(() => {
        if (isShowModel) {
            if (configurationId) {
                getItemConfigurationDetails();
            } else {
                getItemList();
            };
        }
    }, [isShowModel]);

    const getItemList = async () => {
        setPending(true)
        let variables = {}, responseData, newConfigurations;
        let request = getRequestForApi(global.ITEM_CONFIGURATION_LIST, variables, methodType.POST);
        await callHttpRequest(request).then(response => {
            if (response?.status === 201) {
                responseData = response?.data?.response?.data
                SetItemList(responseData);
                newConfigurations = responseData.filter(configure => isStringValue(configure?.id) !== '0');
                if (isArrayValue(newConfigurations).length !== 0) {
                    setSaveItemConfig(true);
                } else {
                    setSaveItemConfig(false);
                };
                setPending(false);
            };
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log("err", err);
        });
    };

    const getItemConfigurationDetails = async () => {
        setPending(true)
        let variables = {}, res, request;
        variables['configuartionId'] = configurationId;
        request = getRequestForApi(global.ITEM_CONFIGURATION_DETAILS, variables, methodType.POST);
        await callHttpRequest(request).then(response => {
            if (response?.status === 201) {
                res = response?.data?.response?.data
                SetItemList(res);
                setPending(false);
            };
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log("err", err);
        });
    };

    const addConfiguration = async () => {
        if (itemList.filter(data => data?.id !== '0')?.length >= 15) {
            alert.show(alertMessages.ITEMS_ADD_ERROR, { type: alertTypes.INFO });
            return;
        };
        setPending(true);
        let variables = {};
        variables.labelName = isStringValue(itemConfig?.labelName);
        variables.labelType = isStringValue(itemConfig?.labelType) || "text";
        variables.isMandatory = isBooleanValue(itemConfig?.isMandatory);
        variables.isSortable = isBooleanValue(itemConfig?.isSortable);
        variables.isSearchable = isBooleanValue(itemConfig?.isSearchable);
        variables.characterLength = isIntegerValue(itemConfig?.characterLength);
        let request = getRequestForApi(global.ITEM_CONFIGURATION_ADD, variables, methodType.POST);
        await callHttpRequest(request).then(response => {
            if (response?.status === 201 && response?.data?.error === 201) {
                alert.show(alertMessages.ITEM_CONFIG_SUCCESS, { type: alertTypes.SUCCESS });
                setItemConfig(null);
                getItemList();
            } else {
                alert.show(response?.data?.response?.data, { type: alertTypes.ERROR });
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log("err", err);
        });
    };

    const editItemConfig = async () => {
        setPending(true);
        let variables = {}, request;
        variables.id = isStringValue(isEditItem.id);
        variables.labelName = isStringValue(isEditItem.labelName);
        variables.labelType = isStringValue(isEditItem.labelType);
        variables.isSortable = isBooleanValue(isEditItem.isSortable);
        variables.isSearchable = isBooleanValue(isEditItem.isSearchable);
        variables.characterLength = isIntegerValue(isEditItem.characterLength);
        variables.isMandatory = isBooleanValue(isEditItem.isMandatory);
        request = getRequestForApi(global.ITEM_CONFIGURATION_EDIT, variables, methodType.POST);
        await callHttpRequest(request).then(response => {
            if (response?.status === 201 && response?.data?.error === 201) {
                alert.show(alertMessages.ITEM_CONFIG_UPDATE_SUCCESS, { type: alertTypes.SUCCESS });
                setIsEditItem(null);
                getItemList();
            } else {
                alert.show(response?.data?.response?.data, { type: alertTypes.SUCCESS });
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log("err", err);
        });
    };

    const deleteItem = async () => {
        setPending(true);
        let variables = {};
        variables.id = isStringValue(deleteItemConfig.id);
        let request = getRequestForApi(global.ITEM_CONFIGURATION_DELETE, variables, methodType.POST);
        await callHttpRequest(request).then(response => {
            if (response?.status === 201 && response?.data?.error === 201) {
                alert.show(alertMessages.ITEM_CONFIG_DELETE_SUCCESS, { type: alertTypes.SUCCESS });
                setDeleteItemConfig(null);
                getItemList();
            } else {
                alert.show(response?.data?.response?.data, { type: alertTypes.SUCCESS });
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log("err", err);
        });
    };

    const saveItemConfiguration = async () => {
        setPending(true);
        let variables;
        let request = getRequestForApi(global.SAVE_ITEM_CONFIG, variables, methodType.POST);
        await callHttpRequest(request).then(response => {
            if (response?.status === 201 && response?.data?.error === 201) {
                alert.show(response?.data?.response?.data, { type: alertTypes.SUCCESS });
                setDeleteItemConfig(null);
                onSuccess();
                getItemList();
                handleClose();
            } else {
                alert.show(response?.data?.response?.data, { type: alertTypes.SUCCESS });
            }
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log("err", err);
        });
    };

    const handleClose = () => {
        setIsShowModel(false);
        setItemConfig(null);
        setSaveItemConfig(false);
        if (configurationId) {
            setConfigurationId(null);
        };
    };

    let configurationColumns = [
        {
            name: <th>Label Name</th>,
            selector: row => row?.labelName,
            sortable: true,
            cell: row => (<>
                {isEditItem && isStringValue(row?.id) !== '0' && isStringValue(isEditItem?.id) === isStringValue(row?.id) ?
                    <CustomInput
                        fieldType={"text"}
                        setFieldData={setIsEditItem}
                        filedData={isEditItem}
                        fieldId={'#labelName'}
                        keyName={'labelName'}
                        placeholderType={"Enter your label Name"}
                        classType={"form-control"}
                        isrequired="true"
                    /> :
                    <p className={isStringValue(row?.id) === '0' ? 'font-weight-bold' : ''}>{row?.labelName}</p>
                }
            </>)
        },
        {
            name: <th>Label Type</th>,
            selector: row => row?.labelType,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.labelType)?.toLocaleString()}
            </>)
        },
        {
            name: <th>Mandatory</th>,
            selector: row => isBooleanValue(row?.isMandatory),
            sortable: true,
            cell: row => (<>
                {isEditItem && isStringValue(row?.id) !== '0' && isStringValue(isEditItem?.id) === isStringValue(row?.id) ?
                    <input type="checkbox" value={isBooleanValue(isEditItem?.isMandatory)} checked={isBooleanValue(isEditItem?.isMandatory)} onChange={() => setIsEditItem({ ...isEditItem, isMandatory: !isBooleanValue(isEditItem?.isMandatory) })} /> :
                    <span data-tooltip={isStringValue(row?.isMandatory)}>
                        <span className='ellipsis'>
                            {isBooleanValue(row?.isMandatory) ? <>✅</> : <>❌</>}
                        </span>
                    </span>
                }
            </>),
            center: 'true'
        },
        {
            name: <th>Sortable</th>,
            selector: row => isBooleanValue(row?.isSortable),
            sortable: true,
            cell: row => (<>
                {isEditItem && isStringValue(row?.id) !== '0' && isStringValue(isEditItem?.id) === isStringValue(row?.id) ?
                    <input type="checkbox" value={isBooleanValue(isEditItem?.isSortable)} checked={isBooleanValue(isEditItem?.isSortable)} onChange={() => setIsEditItem({ ...isEditItem, isSortable: !isBooleanValue(isEditItem?.isSortable) })} /> :
                    <span data-tooltip={isStringValue(row?.isSortable)}>
                        <span className='ellipsis'>
                            {isBooleanValue(row?.isSortable) ? <>✅</> : <>❌</>}
                        </span>
                    </span>
                }
            </>),
            center: 'true'
        },
        {
            name: <th>Searchable</th>,
            selector: row => isBooleanValue(row?.isSearchable),
            sortable: true,
            cell: row => (<>
                {isEditItem && isStringValue(row?.id) !== '0' && isStringValue(isEditItem?.id) === isStringValue(row?.id) ?
                    <input type="checkbox" value={isBooleanValue(isEditItem?.isSearchable)} checked={isBooleanValue(isEditItem?.isSearchable)} onChange={() => setIsEditItem({ ...isEditItem, isSearchable: !isBooleanValue(isEditItem?.isSearchable) })} /> :
                    <span data-tooltip={isStringValue(row?.isSearchable)}>
                        <span className='ellipsis'>
                            {isBooleanValue(row?.isSearchable) ? <>✅</> : <>❌</>}
                        </span>
                    </span>
                }
            </>),
            center: 'true'
        },
        {
            name: <th>Character Length</th>,
            selector: row => row?.characterLength,
            sortable: true,
            cell: row => (<>
                {isEditItem && isStringValue(row?.id) !== '0' && isStringValue(isEditItem?.id) === isStringValue(row?.id) ?
                    <CustomInput
                        fieldType={"number"}
                        setFieldData={setIsEditItem}
                        filedData={isEditItem}
                        fieldId={'#characterLength'}
                        keyName={'characterLength'}
                        placeholderType={"Enter character length"}
                        classType={"form-control"}
                        isrequired="true"
                    /> :
                    isIntegerValue(row?.characterLength)?.toLocaleString()
                }
            </>)
        }
    ];

    if (!configurationId) {
        configurationColumns.splice(configurationColumns?.length, 0, {
            name: <th>Action</th>,
            selector: row => row?.id,
            cell: row => (<>{isStringValue(row?.id) !== '0' && <div className='edit-delete-btn'>
                {isEditItem && isStringValue(isEditItem?.id) === isStringValue(row?.id) ?
                    <ThemeButton className='btn btn-secondary' onClick={() => editItemConfig('update')}>Save</ThemeButton> :
                    <ThemeButton className='btn btn-light btn-border'
                        onClick={() => {
                            if (isEditItem?.id && isStringValue(isEditItem?.id) !== isStringValue(row?.id)) {
                                alert.show('Please save selected configuration first', { type: alertTypes.INFO });
                                return;
                            }
                            setIsEditItem(row)
                        }}
                    >Edit</ThemeButton>
                }
                {isEditItem && isStringValue(isEditItem?.id) === isStringValue(row?.id) ?
                    <ThemeButton className='btn btn-danger' onClick={() => setIsEditItem(null)}>Discard</ThemeButton> :
                    <ThemeButton className={`btn btn-danger ${isStringValue(itemList[itemList.length - 1]?.id) === isStringValue(row?.id) ? '' : 'disable-button'}`} onClick={() => setDeleteItemConfig(row)}>Delete</ThemeButton>
                }
            </div>
            }</>)
        });
    };

    const disableButton = () => {
        let isValid = false;
        if (!itemConfig || !itemConfig?.labelName || !itemConfig?.characterLength) {
            if ((itemConfig?.labelType === 'checkbox' || itemConfig?.labelType === 'radio') && !itemConfig?.characterLength && itemConfig?.labelName) {
                return isValid = true;
            };
            return isValid;
        }
        return isValid = true;
    };

    const conditionalRowStyles = [
        {
            when: row => isStringValue(row?.id) === '0',
            style: {
                // backgroundColor: 'rgb(210, 210, 210)',
                fontWeight: 'bold'
            },
        },
    ];

    const tableCustomStyles = {
        headCells: {
            style: {
                // fontSize: '10px',
                // fontWeight: 'bold',
                // paddingLeft: '0 8px',
                // justifyContent: 'center',
                backgroundColor: '#F1F1F9'
            },
        },
    }
    return (
        <>
            {pending && <CustomLoader />}
            <div className={`modal ${isShowModel ? 'show page-over' : "fade"}`} id={isShowModel} tabIndex="0" aria-labelledby={isShowModel} aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static" >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-head d-flex gap-2">
                                <h5 style={elementStyle} className="modal-title" id="exampleModalLabel">Item Configuration</h5>
                            </div>
                            <div className="close">
                                <button type="button" className="small btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()} />
                            </div>
                        </div>
                        {!configurationId && <>
                            <div className="modal-body">
                                <label style={elementStyle} htmlFor='label name'>Label Name <span className="required">*</span></label>
                                <div className="before-input-text mb-2">
                                    <CustomInput
                                        fieldType={"text"}
                                        setFieldData={setItemConfig}
                                        filedData={itemConfig}
                                        fieldId={'#labelName'}
                                        keyName={'labelName'}
                                        placeholderType={"Enter label Name"}
                                        classType={"form-control"}
                                        isrequired="true"
                                        style={elementStyle}
                                    />
                                </div>
                                {itemConfig?.labelType !== 'checkbox' && itemConfig?.labelType !== 'radio' && <div className="input-box mt-2">
                                    <label htmlFor="Character Length" style={elementStyle}>Character Length <span className="required">*</span></label>
                                    <CustomInput
                                        classType={"form-control"}
                                        fieldType={"number"}
                                        fieldId={'#CLcharacterLength'}
                                        setFieldData={setItemConfig}
                                        filedData={itemConfig}
                                        keyName={'characterLength'}
                                        maxCharacterLength={4}
                                        placeholderType={"Enter character length"}
                                        style={elementStyle}
                                    />
                                </div>}
                                {itemConfig?.labelType !== 'checkbox' && itemConfig?.labelType !== 'radio' && <div className="input-box mt-2">
                                    <input type="checkbox" value={isBooleanValue(itemConfig?.isMandatory)} checked={isBooleanValue(itemConfig?.isMandatory)} onChange={() => setItemConfig({ ...itemConfig, isMandatory: !isBooleanValue(itemConfig?.isMandatory) })} />&nbsp;
                                    <label htmlFor='Mandatory' style={elementStyle}>Mandatory</label>
                                    <input type="checkbox" value={isBooleanValue(itemConfig?.isSearchable)} checked={isBooleanValue(itemConfig?.isSearchable)} onChange={() => setItemConfig({ ...itemConfig, isSearchable: !isBooleanValue(itemConfig?.isSearchable) })} />&nbsp;
                                    <label htmlFor='Searchable' style={elementStyle}>Searchable</label>
                                    <input type="checkbox" value={isBooleanValue(itemConfig?.isSortable)} checked={isBooleanValue(itemConfig?.isSortable)} onChange={() => setItemConfig({ ...itemConfig, isSortable: !isBooleanValue(itemConfig?.isSortable) })} />&nbsp;
                                    <label htmlFor='Sortable' style={elementStyle}>Sortable</label>
                                </div>}
                            </div>
                            <div className="modal-footer yesNoBtn">
                                <ThemeButton type="button" className={`btn btn-primary btn-sm ${!disableButton() ? 'disable-button' : ''}`} onClick={() => { setItemConfig(null);; addConfiguration() }}>Add Configuration</ThemeButton>
                                <ThemeButton type="button" className={`btn btn-primary btn-sm ${saveItemConfig ? '' : 'disable-button'}`} onClick={() => saveItemConfiguration()}>Save Configuration</ThemeButton>
                            </div>
                        </>}
                        <div className='plans-modal-table'>
                            <CommonTable
                                columns={configurationColumns}
                                data={itemList}
                                fixedHeader={true}
                                keyField={'labelName'}
                                fixedHeaderScrollHeight={'300px'}
                                progressPending={pending}
                                progressComponent={<CustomLoader />}
                                noDataComponent={<NoRecord />}
                                customStyles={tableCustomStyles}
                                pagination={false}
                            // conditionalRowStyles={conditionalRowStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DeleteConfig deleteConfigModal={deleteItemConfig} heading={'are you sure you want to delete this Configuration'} handleSubmit={deleteItem} handleClose={setDeleteItemConfig} />
        </>
    )

}

export default AddItemConfig
