import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { isStringValue } from '../utility-files/data-util/DataHandling';

function HeaderNavIcons({ currentTab, children, ...rest }) {
    const themeData = useSelector((state) => state.reduxReducer.themeData);
    const headerStyle = {
        color: isStringValue(themeData.linkColor) + '80',
        fontFamily: isStringValue(themeData.fontName)
    };

    const headerSelectedStyle = {
        color: isStringValue(themeData.linkColor),
        fontFamily: isStringValue(themeData.fontName),
        borderBottom: '5px solid ' + isStringValue(themeData.linkColor)
    };
    return (
        <Link style={currentTab ? headerSelectedStyle : headerStyle} {...rest}>
            {children}
        </Link>
    )
}

export default HeaderNavIcons
