import React, { useEffect, useState } from 'react'
import CustomLoader from '../custom-ui/CustomLoader';
import * as global from '../constants/global'
import { getRequestForApi } from '../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../utility-files/api-util/HttpRequest';
import { alertMessages, alertTypes } from '../utility-files/helper-function/HelperFunction';
import { isArrayValue, isBooleanValue, isIntegerValue } from '../utility-files/data-util/DataHandling';
import AddUpdateTheme from '../custom-popup-model/AddUpdateTheme';
import DeleteConfig from '../custom-popup-model/DeleteConfig';
import { useAlert } from 'react-alert';
import ComponentHandler from '../pages/common-module/ComponentHandler';
import ThemeButton from './ThemeButton';
import { useDispatch, useSelector } from 'react-redux';
import { currentTheme } from '../redux/reduxSlice';

function QedTheme() {
    const dispatch = useDispatch();
    const alert = useAlert();
    const [pending, setPending] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [themesList, setThemesList] = useState(null);
    const themeData = useSelector((state) => state.reduxReducer.themeData);
    useEffect(() => {
        getTheme();
    }, []);

    const getTheme = async () => {
        setThemesList(null);
        setPending(true);
        let request = getRequestForApi(global.GET_THEMES_LIST, undefined, methodType.POST)
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                if (response?.data?.error === 201) {
                    const data = response?.data?.response?.data;
                    setThemesList(data);
                };
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const readCurrentTheme = async () => {
        alert.show(alertMessages.APPLY_THEME_SUCCESS, { type: alertTypes.SUCCESS });
        setPending(true);
        let request = getRequestForApi(global.READ_CURRENT_THEME, undefined, methodType.POST)
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                if (response?.data?.error === 201) {
                    const data = response?.data?.response?.data;
                    dispatch(currentTheme(data))
                };
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const deleteTheme = async () => {
        setPending(true);
        let variables = {}
        variables.id = isIntegerValue(deleteConfirm['id']);
        let request = getRequestForApi(global.DELETE_THEME, variables, methodType.POST)
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                if (response?.data?.error === 201) {
                    alert.show(alertMessages.DELETE_THEME_SUCCESS, { type: alertTypes.SUCCESS });
                    setDeleteConfirm(null);
                    getTheme();
                };
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const applyTheme = async ({ id }) => {
        setPending(true);
        let variables = {}
        variables.id = isIntegerValue(id);
        let request = getRequestForApi(global.APPLY_THEME, variables, methodType.POST)
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                if (response?.data?.error === 201) {
                    readCurrentTheme();
                    getTheme();
                };
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        });
    };

    const THEMES = isArrayValue(themesList).map((element, index) => {
        return <div className='col-lg-3 col-md-4 col-12 py-3' key={`tokens_plan_${index}`}>
            <div className="card p-0" style={{
                width: "22rem",
                height: "250px",
                background: element.backgroundColor,
                fontFamily: element.fontName,
                color: element.textColor
            }}>
                <div className="card-body position-relative">
                    <h5 className="card-title" style={{ color: element.textColor, fontFamily: element.fontName }}>{element?.name}</h5>
                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<a href='#' style={{ color: element.linkColor }}>Click here...</a></p>
                    <div className='card-btns-theme'>
                        {isBooleanValue(element.isChangable) && <><a href="#" style={{ color: element.buttonTextColor, backgroundColor: element.buttonBackgroundColor }} className="btn" onClick={() => setShowModal(element)}>Edit</a>&nbsp;&nbsp;</>}
                        {themeData['id'] !== element['id'] ? <>
                            {isBooleanValue(element.isChangable) &&
                                <>  <a href="#" style={{ color: element.buttonTextColor, backgroundColor: element.buttonBackgroundColor }} className="btn" onClick={() => setDeleteConfirm(element)}>Delete</a>&nbsp;&nbsp;</>}
                            <a href="#" style={{ color: element.buttonTextColor, backgroundColor: element.buttonBackgroundColor }} className="btn" onClick={() => applyTheme(element)}>Apply</a>
                        </> : <a href="#" style={{ color: element.buttonTextColor, backgroundColor: element.buttonBackgroundColor }} className="btn" onClick={() => alert.show('Theme already in use', { type: alertTypes.INFO })}>In use</a>}
                    </div>
                </div>
            </div>
        </div>
    });

    return (
        <>
            {pending && <CustomLoader />}
            <ComponentHandler>
                <div className="box table_structure overflow-scroll">
                    <div className="actionRow">
                        <div className="left" >
                            <ThemeButton className="addButton pointer" onClick={() => setShowModal(!showModal)}>
                                <i className="bi bi-plus-circle"></i>Create Theme
                            </ThemeButton>
                        </div>
                    </div>
                    <div className='feedback-table'>
                        <div className="container-fluid p-0">
                            <div className="row">
                                {THEMES}
                            </div>
                        </div>
                    </div>
                </div>
            </ComponentHandler>
            <AddUpdateTheme showModal={showModal} setShowModal={setShowModal} onSuccess={getTheme} applyTheme={applyTheme} />
            <DeleteConfig deleteConfigModal={deleteConfirm} heading={'Are you sure you want to delete this theme?'} handleSubmit={deleteTheme} handleClose={setDeleteConfirm} />
        </>
    )
}

export default QedTheme