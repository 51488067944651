import React, { useEffect, useState } from 'react'
import * as global from '../../constants/global'
import CommonTable from '../common-module/CommonTable';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import { getDateFormat, getTimeFormat } from '../../utility-files/date-util/DateHandling';
import CustomLoader from '../../custom-ui/CustomLoader';
import * as DataHandling from '../../utility-files/data-util/DataHandling';
import NoRecord from '../common-module/NoRecord';
import InviteUser from '../../custom-popup-model/InviteUser';
import ComponentHandler from '../common-module/ComponentHandler';
import ThemeButton from '../../qed-theme-module/ThemeButton';
import * as HelperFunction from '../../utility-files/helper-function/HelperFunction';
import AssignRole from '../../custom-popup-model/AssignRole';
import TableContentHandler from '../../qed-theme-module/TableContentHandler';
import { useSelector } from 'react-redux';

function UsersList() {
    const [isShowModel, setIsShowModel] = useState(false);
    const [showAssignRoleModel, setShowAssignRoleModel] = useState(false);
    const [usersList, setUsersList] = useState(null);
    const [pending, setPending] = useState(false);
    const user_data = useSelector((state) => state.reduxReducer);
    const user_details = user_data.userData;
    const hasAccess = HelperFunction.accessManager(user_details?.role);

    useEffect(() => {
        getUsersList();
    }, []);

    const getUsersList = async () => {
        setPending(true);
        let request = getRequestForApi(global.USERS_LIST, undefined, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201 && response?.data?.error === 200) {
                let responseData = response?.data?.response?.data;
                setUsersList(responseData);
                setIsShowModel(false);
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        })
    };

    function changeRoleAccess(row) {
        if (user_details?.['id'] !== row?.['id'] && DataHandling.stringValueLowerCase(row?.status) === 'active') {
            return true;
        };
    };

    let usersColumns = [
        {
            name: <TableContentHandler header={'Name'} />,
            selector: row => row?.displayName,
            sortable: true,
            wrap: true,
            cell: row => (<TableContentHandler data={row?.displayName} />),
        },
        {
            name: <TableContentHandler header={'Wallet Name'} />,
            selector: row => row?.walletName,
            sortable: true,
            wrap: true,
            cell: row => (<TableContentHandler data={row?.walletName} />),
        },
        {
            name: <TableContentHandler header={'Company Name'} />,
            selector: row => row?.companyName,
            sortable: true,
            wrap: true,
            cell: row => (<TableContentHandler data={row?.companyName} />),
        },
        {
            name: <TableContentHandler header={'Email'} />,
            selector: row => row?.email,
            sortable: true,
            wrap: true,
            cell: row => (<TableContentHandler data={row?.email} />),
        },
        {
            name: <TableContentHandler header={'Contact Number'} />,
            selector: row => row?.contactNumber,
            sortable: true,
            wrap: true,
            cell: row => (<TableContentHandler data={row?.contactNumber} />),
        },
        {
            name: <TableContentHandler header={'Status'} />,
            selector: row => row?.status,
            sortable: true,
            wrap: true,
            cell: row => (<span data-tooltip={DataHandling.isStringValue(row?.status)}>
                <span className='ellipsis'>
                    {DataHandling.isStringValue(row?.status)?.toLocaleLowerCase() === 'active' ? <>
                        <span className="logged-in">●</span>
                    </> : DataHandling.isStringValue(row?.status)?.toLocaleLowerCase() === 'inactive' ? <>
                        <span className="logged-out">●</span>
                    </> : <>
                        <span className="pending-out">●</span>
                    </>}
                </span>
            </span>),
        },
        {
            name: <TableContentHandler header={'Create Date'} />,
            selector: row => row?.role,
            sortable: true,
            width: '15%',
            cell: row => (<TableContentHandler data={`${getDateFormat(row?.createdAt)} ${getTimeFormat(row?.createdAt)}`} />)
        },
        {
            name: <TableContentHandler header={'User Role'} />,
            selector: row => getDateFormat(row?.createdAt),
            sortable: true,
            width: '15%',
            cell: row => (<TableContentHandler data={DataHandling.isStringValue(row?.role)?.replace(/([A-Z])/g, ' $1').trim()} />)
        }
    ];

    if (DataHandling.stringValueLowerCase(HelperFunction.roleTypes.COMPANY_ADMIN) === DataHandling.stringValueLowerCase(user_details?.['role'])) {
        usersColumns.splice(usersColumns?.length, 0, {
            name: <TableContentHandler header={'Action'} />,
            selector: row => row.email,
            cell: row => (
                changeRoleAccess(row) ? <>
                    <ThemeButton type="button" className={`btn btn-primary`} onClick={() => setShowAssignRoleModel(row)}>Change Role</ThemeButton>
                </> : <></>
            ),
            width: "10%"
        });
    };

    return (
        <>
            {pending && <CustomLoader />}
            <ComponentHandler>
                <div className="box table_structure item-data-table">
                    <div className="actionRow">
                        <div className={`left ${hasAccess?.createItemAccess ? "" : "disable-button"}`}>
                            <ThemeButton className="addButton pointer" onClick={() => setIsShowModel(!isShowModel)}>
                                <i className="bi bi-plus-circle"></i>Add Other Admins
                            </ThemeButton>
                        </div>
                    </div>
                    <CommonTable
                        columns={usersColumns}
                        data={usersList}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={'400px'}
                        noDataComponent={<NoRecord />}
                    />
                </div>
                <InviteUser isShowModel={isShowModel} setIsShowModel={setIsShowModel} onSuccess={getUsersList} />
                <AssignRole showModal={showAssignRoleModel} setShowAssignRoleModel={setShowAssignRoleModel} onSuccess={getUsersList} />
            </ComponentHandler>
        </>
    )
}

export default UsersList
