import './App.css';
import CacheBuster from 'react-cache-buster';
import { default as data } from '../package.json'
import Routing from './config/Routing';
import CustomLoader from './custom-ui/CustomLoader';

function App() {
    // const isProduction = process.env.NODE_ENV === 'production';
    const isProduction = true;
    return (
        <CacheBuster
            currentVersion={data.version}
            isEnabled={isProduction} //If false, the library is disabled.
            loadingComponent={<CustomLoader />} //If not pass, nothing appears at the time of new version check.
        >
            <Routing />
        </CacheBuster>
    );
}

export default App;
