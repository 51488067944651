import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useParams } from "react-router-dom";
import { decryptData } from "../../utility-files/data-encryption-util/DataEncryption";
import * as global from '../../constants/global';
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import { isIntegerValue } from "../../utility-files/data-util/DataHandling";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
function PaymentPage() {
    const [clientSecret, setClientSecret] = useState(null);
    const [clientSecretId, setClientSecretId] = useState(null);
    const [pending, setPending] = useState(false);
    const { secret } = useParams();

    useEffect(() => {
        createPaymentIntent();
    }, []);

    const createPaymentIntent = async () => {
        setPending(true);
        // Create PaymentIntent as soon as the page loads
        // let variables = JSON.parse(decryptData(secret));
        let planData = JSON.parse(decryptData(secret))
        let variables = {}
        variables.planId = isIntegerValue(planData?.id);
        variables.noOfTokens = isIntegerValue(planData?.noOfTokens);
        variables.amount = isIntegerValue(planData?.amount * 100);
        variables.tokenId = ""
        variables.description = (planData?.description);
        let request = getRequestForApi(global.QED_CREATE_PAYMENT_INTENT, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                setClientSecret(response?.data?.response?.data?.client_secret);
                setClientSecretId(response?.data?.response?.data?.id);
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        // Fully customizable with appearance API.
        appearance,
    };

    return (
        <>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm options={options} clientSecretId={clientSecretId} />
                </Elements>
            )}
        </>
    );
}

export default PaymentPage