import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PATH_SLUG, path } from '../utility-files/helper-function/HelperFunction';
import PrivateRoute from './PrivateRoute';
import LoginSignupRoute from './LoginSignupRoute';
import CustomLoader from '../custom-ui/CustomLoader';
import PrivacyPolicy from '../pages/user-module/PrivacyPolicy';
import Terms from '../pages/user-module/Terms';
import TermsMobile from '../pages/user-module/TermsMobile';
import AcceptUserInvite from '../pages/common-module/AcceptUserInvite';
import DeleteAccount from '../custom-popup-model/DeleteAccount';
import ConfirmDelete from '../custom-popup-model/ConfirmDelete';
import ItemConfiguration from '../pages/main-module/ItemConfiguration';
import QedTheme from '../qed-theme-module/QedTheme';
import UsersList from '../pages/main-module/UsersList';
// import AdminAccessManager from './AdminAccessManager';
import PaymentPage from "../pages/payments-module/Payment";
import CheckoutPlan from '../pages/common-module/CheckoutPaln';
import DetectOS from '../pages/user-module/DetectOS';
import ProductDetail from '../pages/common-module/ProductDetail';
import Header from '../common-pages/Header';
const SuccessPage = lazy(() => import("../pages/common-module/AcceptSuccess"))
const Error = lazy(() => import('../pages/user-module/Error'));
const TransactionHistory = lazy(() => import('../pages/common-module/TransactionHistory'));
const Login = lazy(() => import('../pages/user-module/Login'));
const Signup = lazy(() => import('../pages/user-module/Singup'));
const Home = lazy(() => import('../pages/main-module/Home'));
const Items = lazy(() => import('../pages/main-module/Items'));
const ItemDetails = lazy(() => import('../pages/common-module/ItemDetails'));
const Tokens = lazy(() => import('../pages/main-module/Tokens'));
const MFAuth = lazy(() => import('../pages/user-module/MFAuth'));
const ResetPassword = lazy(() => import('../custom-popup-model/ResetPassword'));
const AcceptInvite = lazy(() => import('../custom-popup-model/AcceptInvite'));
const FAQ = lazy(() => import('../pages/common-module/FAQ'));
const Welcome = lazy(() => import('../pages/common-module/Welcome'));
const Checkout = lazy(() => import('../pages/common-module/Checkout'));
// const Payment = lazy(() => import('../pages/payments-module/Payment'));
// const PaymentSuccess = lazy(() => import('../pages/payments-module/PaymentSuccess'));
const Cancel = lazy(() => import('../pages/payments-module/Cancel'));
const StripeSuccess = lazy(() => import('../pages/payments-module/StripeSuccess'));
const FeedbackList = lazy(() => import('../pages/main-module/FeedbackList'));
const AboutUs = lazy(() => import('../pages/common-module/AboutUs'));

function Routing() {
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path={path.ABOUT_US} element={<Suspense fallback={<CustomLoader />}><AboutUs /></Suspense>} />
        <Route path={path.WELCOME} element={<Suspense fallback={<CustomLoader />}><Welcome /></Suspense>} />
        <Route path={path.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={path.TERMS} element={<Terms />} />
        <Route path={path.TERMS_MOBILE} element={<TermsMobile />} />
        <Route path={'/detectos'} element={<DetectOS />} />
        <Route path={path.PRODUCT_DETAILS} element={<><Header /><ProductDetail /></>} />
        <Route element={<LoginSignupRoute />}>
          <Route path={path.LOGIN} element={<Login />} />
          <Route path={path.MFA + PATH_SLUG.MFA} element={<MFAuth />} />
          <Route path={path.CONFIRM_PASSWORD + PATH_SLUG.CONFIRM_PASSWORD} element={<ResetPassword />} />
          <Route path={path.ACCEPT_INVITE + PATH_SLUG.ACCEPT_INVITE} element={<AcceptInvite />} />
          <Route path={path.ACCEPT_USER_INVITE + PATH_SLUG.ACCEPT_USER_INVITE} element={<AcceptUserInvite />} />
          <Route path={path.CONFIRM_DELETE_ACCOUNT + PATH_SLUG.CONFIRM_DELETE_ACCOUNT} element={<ConfirmDelete />} />
          <Route path={path.SIGNUP} element={<Signup />} />
          <Route path={path.DELETEACCOUNT} element={<DeleteAccount />} />
          <Route path={path.INVITE_ACCEPT_SUCCESS} element={<SuccessPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={path.ERROR} element={<Error />} />
          <Route path={path.HOME} element={<Home />} />
          <Route path={path.ITEMS} element={<Items />} />
          <Route path={path.TOKENS} element={<Tokens />} />
          <Route path={path.ITEM_DETAILS + PATH_SLUG.ITEM_DETAILS} element={<ItemDetails />} />
          <Route path={path.FAQ} element={<FAQ />} />
          <Route path={path.PAYMENTS + PATH_SLUG.PAYMENTS} element={<PaymentPage />} />
          {/* <Route path={path.PAYMENTS + PATH_SLUG.PAYMENTS} element={<Payment/>} /> */}
          {/* <Route path={path.SUCCESS + PATH_SLUG.PAYMENTS} element={<PaymentSuccess />} /> */}
          <Route path={path.CANCEL} element={<Cancel />} />
          <Route path={path.SUCCESS + '/:session'} element={<StripeSuccess />} />
          <Route path={path.SUCCESS} element={<StripeSuccess />} />
          <Route path={path.HISTORY} element={<TransactionHistory />} />
          <Route path={path.FEEDBACK} element={<FeedbackList />} />
          <Route path={path.USERS} element={<UsersList />} />
          <Route path={path.CHECKOUT} element={<Checkout />} />
          <Route path={path.CHECKOUT_PLAN} element={<CheckoutPlan />} />
          <Route path={path.QED_THEME_CONFIGURATION} element={<QedTheme />} />
          <Route path={path.ITEM_CONFIGURATION} element={<ItemConfiguration />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Routing