import React from 'react'
import { Header_Elements } from '../utility-files/helper-function/HelperFunction';
import { useLocation } from 'react-router-dom';
import HeaderNavIcons from '../qed-theme-module/HeaderNavIcons';
import { isStringValue } from '../utility-files/data-util/DataHandling';
import * as Logos from '../qed-theme-module/qed-logos/Logos';
import { useSelector } from 'react-redux';

function HeaderNavLinks({ userDetails }) {
    const location = useLocation();
    const themeData = useSelector((state) => state.reduxReducer.themeData);

    const headerStyle = {
        color: isStringValue(themeData.linkColor) + '80',
        fontFamily: isStringValue(themeData.fontName)
    };

    const headerSelectedStyle = {
        color: isStringValue(themeData.linkColor),
        fontFamily: isStringValue(themeData.fontName),
        borderBottom: '5px solid ' + isStringValue(themeData.linkColor)
    };

    const NAV_LINKS = Header_Elements?.map((tabData, index) => {
        const currentTab = location?.pathname === tabData.path;
        const fillColor = currentTab ? isStringValue(headerSelectedStyle?.color) : isStringValue(headerStyle?.color);
        function getNavIcons() {
            if (tabData?.id === 1) {
                return <Logos.HomeLogo fillColor={fillColor} />
            } else if (tabData?.id === 2) {
                return <Logos.ItemsLogo fillColor={fillColor} />
            } else if (tabData?.id === 3) {
                return <Logos.TokenIcon fillColor={fillColor} />
            } else if (tabData?.id === 4) {
                return <Logos.UsersIcon fillColor={fillColor} />
            } else if (tabData?.id === 5) {
                return <Logos.FeedbackIcon fillColor={fillColor} />
            } else if (tabData?.id === 6) {
                return <Logos.ConfigurationIcon fillColor={fillColor} />
            };
        };

        return <li key={`nav-item-${index}-${tabData?.path}`}>
            {tabData?.id === 6 && userDetails?.role === "CompanyAdmin" ? <HeaderNavIcons to={tabData?.path} currentTab={currentTab} className={`${currentTab ? 'active' : ''}`}>
                {getNavIcons()}
                {tabData?.tabName}
            </HeaderNavIcons> : tabData?.id !== 6 && <HeaderNavIcons to={tabData?.path} currentTab={currentTab} className={`${currentTab ? 'active' : ''}`}>
                {getNavIcons()}
                {tabData?.tabName}
            </HeaderNavIcons>}
        </li>
    });

    return (
        <div className="menuBox">
            {NAV_LINKS}
        </div>
    )
}

export default HeaderNavLinks
