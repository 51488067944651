import React, { useState } from "react";
import { useAlert } from "react-alert";
import * as global from '../constants/global';
import CustomInput from '../custom-input-fields/CustomInput';
import { alertMessages, alertTypes } from "../utility-files/helper-function/HelperFunction";
import { isStringValue } from "../utility-files/data-util/DataHandling";
import { callHttpRequest, methodType } from "../utility-files/api-util/HttpRequest";
import { getRequestForApi } from "../utility-files/api-util/CommonRequest";
import Image from "../pages/common-module/Image";
import { IMAGE_TYPES, PLACE_HOLDER_TEXT } from "../utility-files/default-values/DefaultValues";
import CustomLoader from "../custom-ui/CustomLoader";
import ThemeButton from "../qed-theme-module/ThemeButton";

function DeleteAccount() {
    const alert = useAlert();
    const [deleteData, setDeleteData] = useState()
    const [pending, setPending] = useState(false)
    const AccountDelete = async () => {
        setPending(true);
        let variables = {};
        variables.email = isStringValue(deleteData?.email);
        let request = getRequestForApi(global.SEND_INVITE_LINK, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show(alertMessages.UPDATE_USER_EMAIL, { type: alertTypes.SUCCESS });
                setDeleteData()
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
        });
    };

    return (
        <>
            {pending && <CustomLoader />}
            <form>
                <div className="form-row">
                    <h1>Mail will be send to entered email id having link to delete account linked with the email id.</h1>
                </div>
                <div className="form-row">
                    <label htmlFor="email">Email ID</label>
                    <div className="input-box d-flex">
                        <Image src={IMAGE_TYPES.USER_LOGIN} alt="" />
                        <CustomInput
                            fieldType={"email"}
                            setFieldData={setDeleteData}
                            filedData={deleteData}
                            fieldId={'#lemail'}
                            keyName={'email'}
                            placeholderType={PLACE_HOLDER_TEXT.email}
                            isrequired="true"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <ThemeButton type="button" className={`btn1 ${!deleteData?.isEmailValid ? 'disable-button' : ''}`} onClick={() => AccountDelete()}>Submit</ThemeButton>
                </div>
            </form>


        </>
    )

}
export default DeleteAccount