import React from 'react'
import '../../assets/css/policy.css'
function PrivacyPolicy() {
    return (
        <section className="p-3 background">
            <div className="text-center">
                <h5>QED QED LLC PRIVACY POLICY</h5>
            </div>
            <div className="first-para">
                <h6>DRAFT —Attorney/Client Communication—Privileged and Confidential.</h6>
            </div>
            <div className="first-para">
                <p>This Privacy Policy describes how QED QED LLC (“the Company”, “we”, “us” or “our”) handles personal
                    information (“Personal Information”) that we collect through our mobile application that links to
                    this Privacy Policy, as well as through social media, our marketing activities, and other activities
                    described in this Privacy Policy (collectively, “Our NFT Service” or “the Service”). Please read
                    this Privacy Policy carefully. If you have any questions about this Policy, please submit your
                    request via the email set forth below. By using this Service or providing information to us, you
                    agree to be bound by this Privacy Policy and all applicable laws. We reserve the right to cancel
                    Your account if you undertake any acts in violation of this Privacy Policy, or any applicable law of
                    any nature. </p>
            </div>
            <div className="listing">
                <ol>
                    <li>When using the App, you may find links to other apps or sources of information. The Company is
                        not responsible for the privacy practices of other persons or entities.</li>
                    <li>We may revise this Privacy Policy at any time, and we reserve the right to update or modify
                        these terms at any time without prior notice. Your use of the App following any such change
                        constitutes your agreement to be bound by the Privacy Policy as changed.</li>
                    <div>
                        <h6 className="mt-3">Personal Information We Collect</h6>
                        <h6 className="text-center">If you wish not to have Personal Information <br />
                            collected, disclosed or shared with others, <br />
                            please do not use the Service.
                        </h6>
                    </div>
                    <li>
                        This Privacy Policy is subject to all applicable privacy laws and regulations regarding the
                        collection, use and disclosure of personal information. When you create an account, we collect
                        information directly from you, such as your name, phone number, email address, and zip code. The
                        creator of the token may be able to see this information, such as the username, and the name and
                        block chain address. Another user will see a unique blockchain address and the name assigned to
                        the wallet. This information will be visible to the creator of the token. Personal Information
                        may also be obtained from third parties, device information, links, IP addresses, social media
                        outlets, cookies and similar technologies. We also may collect data from you when you use or
                        view our application.
                    </li>
                    <li>We use standard commercial and legal practices for keeping such data confidential, but there is
                        no guarantee that such practices will prevent the disclosure or further distribution of such
                        data beyond the Company.</li>
                    <h6 className="mt-3">How We Use Your Personal Information</h6>
                    <li>We use the Personal Information that we collect about you for the following purposes:
                        <ul>
                            <li>To authenticate and digitally trace your token.</li>
                            <li>To provide you with technical support.</li>
                            <li>To respond to you about any comment or inquiry you have submitted.</li>
                            <li>To prevent or take action against activities that are, or may be, in breach of our Terms
                                and Conditions [insert hyperlink to Terms and Conditions] or of applicable law.</li>
                            <li>To send you important information regarding the App, changes to our terms, conditions
                                and policies and other administrative information.</li>
                            <li>To conduct internal business, such as research and development.</li>
                            <li>To conduct internal business, such as research and development.</li>
                            <li>Certain Personal Information may be collected automatically.</li>
                            <li>To protect our and your rights, safety or property.</li>
                            <li>To audit our internal processes, and to comply with legal and contractual requirements.
                            </li>
                            <li>To prevent and investigate activities that are harmful to you or us, or which violate
                                your or our rights and safety.</li>
                            <li>To create anonymous, aggregated or de-identified data.</li>
                            <li>To provide you with assistance in using the App.</li>
                            <li>As we believe to be necessary or appropriate: (a) under applicable law, including laws
                                outside your country of residence; (b) to comply with legal process; (c) to respond to
                                requests from public and government authorities, including public and government
                                authorities outside your country of residence; (d) to enforce our terms and conditions;
                                (e) to protect our operations or those of any of our affiliates; (f) to protect our
                                rights, privacy, safety or property, and/or that of our affiliates, you or others; and
                                (g) to allow us to pursue available remedies or limit the damages that we may sustain.
                            </li>
                        </ul>
                    </li>
                    <h6 className="mt-3">How Your Personal Information Is Shared</h6>
                    <li>We will not share your Personal Information with any third party, except as described in this
                        Privacy Policy. The personal data we collect from you as described above may be transferred with
                        your tokens transfer. Your consent to this Privacy Policy followed by your submission of such
                        information represents your agreement to such transfers. There are circumstances where we may
                        need to share some of the Personal Information we collect about you or which you provide to us
                        for the purposes set out above - these circumstances are as follows:</li>
                    <ul>
                        <li>To our third-party service providers who provide services such as hosting, data analysis,
                            payment processing, order fulfillment, information technology and related infrastructure
                            provision, customer service, email delivery, auditing services and other similar services.
                        </li>
                        <li>To identify you to anyone to whom you send messages through the App.</li>
                        <li>By you, on message boards, chat, profile pages, blogs and other services to which you are
                            able to post information and materials, if applicable. Please note that any Personal
                            Information you post or disclose through these services will become public and may be
                            available to visitors to the App and to the general public. <b>We urge you to be very
                                careful
                                when deciding to disclose your Personal Information, or any other information, on the
                                App.</b>
                        </li>
                        <li>We will disclose your Personal Information if we believe in good faith that we are permitted
                            or required to do so by law, including in response to a court order, subpoena or other legal
                            demand or request.</li>
                        <li>We may disclose your Personal Information if we feel this is necessary in order to protect
                            or defend our legitimate rights and interests, or those of our users, employees, directors
                            or shareholders, and/or to ensure the safety and security of the Company.</li>
                        <li>In the context of a business transfer: We may transfer your Personal Information to any
                            person or company that acquires all or substantially all of the assets or business of the
                            Company, or on a merger of our business, or in the event of our insolvency.</li>
                        <li>As we believe to be necessary or appropriate: (a) under applicable law, including laws
                            outside your country of residence; (b) to comply with legal process; (c) to respond to
                            requests from public and government authorities, including public and government authorities
                            outside your country of residence; (d) to enforce our terms and conditions; (e) to protect
                            our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety
                            or property, and/or that of our affiliates, you or others; and (g) to allow us to pursue
                            available remedies or limit the damages that we may sustain.</li>
                        <li>Enforce the terms and conditions that relate to our Services.</li>
                        <li>We will not collect or share certain information without your express permission, such as,
                            in iOS devices, camera use, device location, microphone use for direct camera use and your
                            photo library; in Android devices, Internet use, camera use, device location and read/write
                            external storage.</li>
                    </ul>
                    <h6 className="mt-3">Other Information We May Collect</h6>
                    <li><b> Subject to applicable laws and regulations</b>,we may also collect any information that does
                        not
                        reveal your specific identity or does not directly relate to an individual, such as browser
                        information, information collected through cookies, pixel tags and other technologies,
                        demographic information, other information provided by you, and aggregated information (“Other
                        Information”).</li>
                    <h6 className="mt-3">How We Collect Other Information</h6>
                    <li>We and our third-party service providers may collect Other Information in a variety of ways,
                        including, but not limited to:</li>
                    <ul>
                        <li><b>Through Your Device:</b>Certain information such as your location, device model, IP
                            address, your operating system is collected by most browsers or automatically through your
                            device, such as your Media Access Control (MAC) address, computer type (Windows or
                            Macintosh), screen resolution, operating system version, device manufacturer and model,
                            language and Internet browser type and version. We use this information to ensure that the
                            App functions properly.</li>
                        <li><b>Cookies:</b>Cookies are pieces of information stored directly on your mobile device or
                            any other device by a website, containing the details of your interactions with the App.
                            Cookies allow us to collect information such as time spent on the App, pages visited,
                            language preferences and other anonymous traffic data. We and our service providers use the
                            information for security purposes, to facilitate navigation, to display information more
                            effectively, and to personalize your experience while visiting the App, as well as for
                            online tracking purposes. We also use cookies to gather statistical information about the
                            use of the App in order to continually improve its design and functionality, understand how
                            it is used and assist us with resolving questions about it. Cookies further allow us to
                            select which of our content, advertisements or offers are most likely to appeal to you and
                            display them while you are using the App. We may also use cookies in online advertising to
                            track responses to our advertisements.

                            We do not use cookies where prohibited by law. If you do not want information collected
                            through the use of cookies, there is a simple procedure in most browsers that allows you to
                            automatically decline cookies or be given the choice of declining or accepting the transfer
                            to your computer of a particular cookie (or cookies) from a particular site. You may also
                            wish to refer to http://www.allaboutcookies.org. If you choose to decline cookies, you may
                            experience some inconvenience in your use of the App, and some or all of the features,
                            functionality and promotions available through the App may not be available to you.

                            We may use Analytics such as Google Analytics, which uses cookies and other, similar
                            technologies to collect information about Site use anonymously and reports website trends,
                            without identifying individual visitors. We also use an enhancement to Google Analytics
                            called “Demographics and Interest Reporting,” through which Google provides us with
                            information about App users’ demographics (e.g., age, gender) and interests. This
                            information, which Google may collect by tracking a user’s behavior across third-party
                            websites, helps us to learn more about our users. You can learn about Google’s practices in
                            connection with this enhanced tracking and opt out of it by visiting
                            www.google.com/settings/ads or by downloading the Google Analytics opt-out browser add-on,
                            available at https://tools.google.com/dlpage/gaoptout.
                        </li>
                        <li><b>Pixel Tags and Similar Technologies:</b>Pixel tags (also known as web beacons and clear
                            GIFs)
                            may be used, to the extent permitted by law, in connection with some App pages and
                            HTML-formatted email messages to, among other things, track the actions of App users and
                            email recipients, measure the success of our marketing campaigns and compile statistics
                            about App usage and response rates. [XXX-APPLICABLE?]</li>
                    </ul>
                    <h6 className="mt-3">How We Use and Disclose Other Information</h6>
                    <li>We may use and disclose Other Information for any purpose, except where we are required to do
                        otherwise under applicable law. If we are required to treat Other Information as Personal
                        Information under applicable law, then, in addition to the uses listed in the How We Collect
                        Other Information section above, we may use and disclose Other Information for all the purposes
                        for which we use and disclose Personal Information. In some instances, we may combine Other
                        Information with Personal Information. If we do, we will treat the combined information as
                        Personal Information as long as it is combined.</li>
                    <h6 className="mt-3">Third-Party Sites</h6>
                    <li>This Privacy Policy does not address, and we are not responsible for, the privacy, information
                        or other practices of any third parties, including any third party operating any site to which
                        this App contains a link. The inclusion of a link on the App does not imply endorsement of the
                        linked site by us or by our affiliates.</li>
                    <h6 className="mt-3">Security</h6>
                    <li>We use reasonable organizational, technical and administrative measures to protect Personal
                        Information within our organization. Unfortunately, no data transmission or storage system can
                        be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is
                        no longer secure, please immediately notify us in accordance with the Contact Us section below.
                    </li>
                    <li>We will try to comply with your request(s) as soon as reasonably practicable. Please note that
                        if you opt out as described above, we will not be able to remove your Personal Information from
                        the databases of our affiliates or unaffiliated third parties with which we have already shared
                        your Personal Information.</li>
                    <li>Please note that you may delete the App from your mobile device at any time.</li>
                    <h6 className="mt-3">How You Can Access, Change or Suppress Your Personal Information</h6>
                    <ul>
                        <li>The right to access – You have the right to request our Company to provide copies of your
                            personal data. We may charge you a small fee for this service.</li>
                        <li>The right to rectification – You have the right to request that our Company correct any
                            information you believe is inaccurate. You also have the right to request Our Company to
                            complete information you believe is incomplete. If you would like to review, correct,
                            update, suppress, delete or otherwise limit our use of the Personal Information you have
                            provided to us, please contact us in writing at [insert contact email address]. In your
                            request, please make clear what information you would like to have changed, whether you
                            would like to have your Personal Information suppressed from our database or otherwise let
                            us know what limitations you would like to put on our use of your Personal Information. We
                            will try to comply with your request as soon as reasonably practicable. For your protection,
                            we may only implement requests with respect to the Personal Information associated with the
                            email address you use to send us your request, and we may need to verify your identity
                            before implementing your request. Please note that we may need to retain certain information
                            for recordkeeping purposes and/or to complete any transactions that you began prior to your
                            request. There may also be residual information that will remain within our databases and
                            other records, which will not be removed.</li>
                    </ul>
                    <h6 className="mt-3">Retention Period</h6>
                    <li>We will retain your Personal Information for the period necessary to fulfill the purposes
                        outlined in this Privacy Policy unless a longer retention period is required or permitted by
                        law.</li>
                    <h6 className="mt-3">Use of App By Children</h6>
                    <li>We do not knowingly collect Personal Information from children under the age of thirteen (13).
                        We are committed to protecting children’s privacy.</li>
                    <h6 className="mt-3">Choice of Law and Jurisdictional Issues</h6>
                    <li>All controversies concerning the App, the Services and our operations, are to be governed by the
                        laws of the State of New York, notwithstanding any choice of law rules. By downloading or using
                        the App, you hereby consent to the jurisdiction of the courts of the State of New York,
                        including the State or Federal courts therein, as applicable. The App is controlled and operated
                        by us from the United States and is not intended to subject us to the laws or jurisdiction of
                        any state, country or territory other than that of the State of New York, United States.</li>
                    <li>The Company provides the Service “as is.” You expressly understand and agree that the Company
                        shall not be liable, in law or in equity, to you or any third party for any direct, indirect,
                        incidental, lost profits, special, consequential, punitive or exemplary damages, including but
                        not limited to, damages for loss of profits, goodwill, use, data or other intangible losses
                        resulting from: (1) the use or the inability to use the Service; (2) the cost of procurement of
                        substitute goods and services resulting from any goods, data, information, or services purchased
                        or obtained or messages received or transactions entered into through or from the Service; (4)
                        statements of conduct of any third party on the Service; (5) or any other matter relating to
                        this Agreement or the Service, whether as a breach of contract, tort, or any other theory of
                        liability.</li>
                    <h6 className="mt-3">Cross-Border Transfer</h6>
                    <li>Your Personal Information may be stored and processed in any country where we have facilities or
                        in which we engage service providers, and, by using the App, you consent to the transfer of
                        information to countries outside of your country of residence, including the United States,
                        which may have data protection rules that are different from those of your country.</li>
                    <h6 className="mt-3">Sensitive Information</h6>
                    <li>We ask that you not send us, and you not disclose, any sensitive Personal Information (e.g.,
                        Social Security numbers, information related to racial or ethnic origin, political opinions,
                        religion or other beliefs, health or criminal background) on or through the App or otherwise to
                        us.</li>
                    <h6 className="mt-3">Privacy Rights</h6>
                    <li>We collect, use, and share Personal Information of California residents as a “business” under
                        the California Consumer Privacy Act (“CCPA”). The term “Personal Information” in this section
                        has the meaning given in the CCPA but does not include information exempted from the scope of
                        the CCPA. California residents have the rights listed below under the CCPA.</li>
                    <li>Information. You can request the following information about how we have collected and used your
                        Personal Information during the past 12 months:
                        <ul>
                            <li>The categories of Personal Information that we have collected.</li>
                            <li>The categories of sources from which we collected Personal Information.</li>
                            <li>The business or commercial purpose for collecting and/or selling Personal Information.
                            </li>
                            <li>The categories of third parties with which we share Personal Information.</li>
                            <li>The categories of Personal Information that we sold or disclosed for a business purpose.
                            </li>
                            <li>The categories of third parties to whom the Personal Information was sold or disclosed
                                for a business purpose.</li>
                        </ul>
                    </li>
                    <li>Deletion. You can ask us to delete the Personal Information that we have collected from you.
                    </li>
                    <li>Nondiscrimination. You are entitled to exercise the rights described above free from
                        discrimination as prohibited by the CCPA.</li>
                    <li>We do not sell your personal information and will never sell your personal information to third
                        parties without your explicit consent. You may submit requests to exercise your right to
                        information, access or deletion by logging into your account and submitting a request under
                        account settings. Personal Information as described in the How we share your personal
                        information section above.</li>
                    <li>If you are a foreign national who is protected by the General Data Protection Regulation (GDPR),
                        we apply the provisions of the GDPR. The GDPR does not apply to us, the Services and this
                        Privacy Policy, where we and our operations or Services are exempt from the GDPR, or where the
                        GDPR is not applicable to us or our operations or Services.</li>
                    <h6 className="mt-3">Contact Us</h6>
                    <li>If you have any questions about this Privacy Policy, please contact us via [insert contact
                        email]. Because email communications are not always secure, please do not include credit card or
                        other sensitive information in your messages to us.</li>
                    <li>© QED QED LLC____, unless otherwise expressly stated. All rights reserved.</li>
                </ol>
            </div>
        </section>
    )
}

export default PrivacyPolicy
