import React from 'react'
import { vEmail, strongRegularExp, isValidZipCode } from '../constants/constants';
import { isIntegerValue, isStringValue } from '../utility-files/data-util/DataHandling';

const inputTextDefaultLength = 100;
const inputTextAreaDefaultLength = 300;

function CustomInput({
    fieldType,
    classType,
    setFieldData,
    filedData,
    fieldId,
    keyName,
    selectList,
    defaultName,
    isReadOnly,
    getSearchResults,
    headerName,
    objKey,
    placeholderType,
    onMouseOver,
    onMouseOut,
    maxCharacterLength,
    textArearows,
    textAreacols,
    ...rest
}) {
    const handleInputValues = (inputKey, data) => {
        let maxLength, dataHandler;
        maxLength = maxCharacterLength ? data?.length <= isIntegerValue(maxCharacterLength) :
            fieldType === "textarea" ? data?.length <= isIntegerValue(inputTextAreaDefaultLength) :
                data?.length <= inputTextDefaultLength;
        dataHandler = data.replace(/[^\w\s]/gi, "");
        dataHandler = dataHandler.replace("_", "");
        switch (inputKey) {
            case 'color':
                setFieldData(state => ({ ...state, [keyName]: data }));
                break;
            case 'url':
                var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
                if (maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data, isUrlValid: regex.test(data.toLowerCase()) }));
                }
                break;
            case 'text':
                if (keyName === 'description' && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data }));
                } else if (objKey && maxLength) {
                    setFieldData(state => ({ ...state, [objKey]: { ...filedData[objKey], [keyName]: dataHandler } }))
                } else if (keyName === 'confirmPassword' && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data, isConfirmPasswordValid: strongRegularExp?.test(data) }));
                } else if (keyName === 'currentPassword' && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data, isCurrentPasswordValid: strongRegularExp?.test(data) }));
                } else if (keyName === 'password' && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data, isPasswordValid: strongRegularExp?.test(data) }));
                } else if ((keyName === "name" || keyName === "labelName" || keyName?.toLowerCase()?.includes('attribute')) && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data }));
                } else if (maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: dataHandler }));
                }
                break;
            case 'textarea':
                if (maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data }));
                }
                break;
            case 'search':
                if (!headerName) {
                    getSearchResults(data);
                } else {
                    setFieldData(state => ({ ...state, [keyName]: data }));
                }
                break;
            case 'number':
                if (/^[0-9\b]+$/.test(isIntegerValue(parseInt(data))) || data === '') {
                    if (keyName === 'zipCode' && maxLength) {
                        setFieldData(state => ({ ...state, [keyName]: data, isZipCodeValid: isValidZipCode(data) }));
                    } else if (maxLength && parseInt(data[0]) !== 0) {
                        setFieldData(state => ({ ...state, [keyName]: data }));
                    };
                }
                break;
            case 'phone':
                if (dataHandler.match(/^\d+$/) || data === '') {
                    if (keyName === 'otp' && data?.length <= 5) {
                        setFieldData(state => ({ ...state, [keyName]: data }));
                    } else if (maxLength && parseInt(data[0]) !== 0) {
                        setFieldData(state => ({ ...state, [keyName]: data }));
                    }
                }
                break;
            case 'password':
                if (keyName === 'confirmPassword' && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data, isConfirmPasswordValid: strongRegularExp?.test(data) }));
                } else if (keyName === 'currentPassword' && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data, isCurrentPasswordValid: strongRegularExp?.test(data) }));
                } if (keyName && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data, isPasswordValid: strongRegularExp?.test(data) }));
                }
                break;
            case 'email':
                if (keyName && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data, isEmailValid: vEmail?.test(data) }));
                }
                break;
            default:
                if (keyName && maxLength) {
                    setFieldData(state => ({ ...state, [keyName]: data }));
                }
                break;
        };
    };

    const inputValues = () => {
        if (fieldType === "email") {
            return <input
                type={isStringValue(fieldType)}
                id={isStringValue(fieldId)}
                value={filedData && keyName ? isStringValue(filedData[keyName]) : isStringValue(defaultName)}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value)}
                readOnly={isReadOnly === 'true' ? true : false}
                placeholder={isStringValue(placeholderType)}
                maxlength={maxCharacterLength || inputTextDefaultLength}
                {...rest}
            />
        };

        if (fieldType === "color") {
            return <input
                type={isStringValue(fieldType)}
                id={isStringValue(fieldId)}
                value={filedData && keyName ? isStringValue(filedData[keyName]) : isStringValue(defaultName)}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value)}
                readOnly={isReadOnly === 'true' ? true : false}
                placeholder={isStringValue(placeholderType)}
                maxlength={maxCharacterLength || inputTextDefaultLength}
                {...rest}
            />
        };

        if (fieldType === "password") {
            return <input
                type={isStringValue(fieldType)}
                id={isStringValue(fieldId)}
                value={filedData && keyName ? filedData[keyName] : isStringValue(defaultName)}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value)}
                placeholder={isStringValue(placeholderType)}
                maxlength={maxCharacterLength || inputTextDefaultLength}
                {...rest}
            />
        };

        if (fieldType === "text" && !objKey) {
            return <input
                type={isStringValue(fieldType)}
                id={isStringValue(fieldId)}
                value={filedData && keyName ? filedData[keyName] : isStringValue(defaultName)}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value)}
                readOnly={isReadOnly === 'true' ? true : false}
                placeholder={isStringValue(placeholderType)}
                maxlength={maxCharacterLength || inputTextDefaultLength}
                {...rest}
            />
        };

        if (fieldType === "url") {
            return <input
                type={isStringValue('text')}
                id={isStringValue(fieldId)}
                value={filedData && keyName ? filedData[keyName] : isStringValue(defaultName)}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value)}
                readOnly={isReadOnly === 'true' ? true : false}
                placeholder={isStringValue(placeholderType)}
                maxlength={maxCharacterLength || inputTextDefaultLength}
                {...rest}
            />
        };

        if (fieldType === "text" && objKey) {
            return <input
                type={isStringValue(fieldType)}
                id={isStringValue(fieldId)}
                value={filedData && keyName ? filedData[objKey][keyName] : isStringValue(defaultName)}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value.replace(/[^\w\s]/gi, ""))}
                readOnly={isReadOnly === 'true' ? true : false}
                placeholder={isStringValue(placeholderType)}
                maxlength={maxCharacterLength || inputTextDefaultLength}
                {...rest}
            />
        };

        if (fieldType === "textarea") {
            return <textarea
                id={isStringValue(fieldId)}
                name={keyName}
                value={filedData && keyName ? filedData[keyName] : isStringValue(defaultName)}
                rows={textArearows || "4"} cols={textAreacols || "73"}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value.replace(/^[\d\(\)\-+_]+$/.test, ""))}
                readOnly={isReadOnly === 'true' ? true : false}
                placeholder={isStringValue(placeholderType)}
                maxlength={maxCharacterLength || inputTextAreaDefaultLength}
                {...rest}
            />
        };

        if (fieldType === "number") {
            var invalidChars = [
                ".",
                "-",
                "+",
                "e",
                "E"
            ];
            return <input
                type="text"
                id={isStringValue(fieldId)}
                min='1'
                value={filedData && keyName && filedData[keyName] !== 0 ? filedData[keyName] : isStringValue(defaultName)}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value.replace(/[^\w\s]/gi, ""))}
                readOnly={isReadOnly === 'true' ? true : false}
                placeholder={isStringValue(placeholderType)}
                onKeyDown={(e) => {
                    if (invalidChars.includes(e.key)) {
                        e.preventDefault();
                    }
                }}
                onInput={(event) => {
                    event.target.value = event.target.value.replace(/[^0-9+]/g, '')
                }}
                maxlength={maxCharacterLength || inputTextDefaultLength}
                {...rest}
            />
        };

        if (fieldType === "phone") {
            let invalidChars = [
                ".",
                "-",
                "+",
                "e",
                "E"
            ];
            return <input
                type="text"
                id={isStringValue(fieldId)}
                value={filedData && keyName && filedData[keyName] !== 0 ? filedData[keyName] : isStringValue(defaultName)}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value)}
                readOnly={isReadOnly === 'true' ? true : false}
                placeholder={isStringValue(placeholderType)}
                maxlength={maxCharacterLength || inputTextDefaultLength}
                onInput={(event) => {
                    event.target.value = event.target.value.replace(/[^0-9+]/g, '')
                }}
                onKeyDown={(e) => {
                    if (invalidChars.includes(e.key)) {
                        e.preventDefault();
                    }
                }}
                {...rest}
            />
        };

        if (fieldType === "date") {
            return <input
                type={fieldType}
                id={isStringValue(fieldId)}
                value={filedData && keyName && filedData[keyName] ? filedData[keyName] : ''}
                className={isStringValue(classType)}
                onChange={(event) => handleInputValues(fieldType, event.target.value)}
                {...rest}
            />
        };

        if (fieldType === "select" && selectList?.length !== 0) {
            return <select name={`${keyName}`} className={isStringValue(classType)} id={`${keyName}`} value={filedData && keyName ? filedData[keyName] : ''} onChange={(event) => handleInputValues(fieldType, event.target.value)} {...rest}>
                {selectList?.map((item) => {
                    return <option key={item?.id} value={item?.id}>{item?.name}</option>
                })}
            </select>
        };
    };

    return (<>
        {inputValues()}
        {fieldType === "textarea" && <div id="the-count">
            <span id="current">{filedData?.[objKey]?.[keyName]?.length || filedData?.[keyName]?.length || isStringValue(defaultName).length}</span>
            <span id="maximum">/ {maxCharacterLength || inputTextAreaDefaultLength}</span>
        </div>}
    </>)
}

export default CustomInput