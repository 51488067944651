import React, { useState } from "react"
import * as global from '../../constants/global'
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../../custom-ui/CustomLoader";
import { decryptData } from "../../utility-files/data-encryption-util/DataEncryption";
import { isBooleanValue, isStringValue } from "../../utility-files/data-util/DataHandling";
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import { IMAGE_TYPES, getDefaultValues } from "../../utility-files/default-values/DefaultValues";
import { alertMessages, alertTypes, errorMessages, getHostUrl, openWindow, path } from "../../utility-files/helper-function/HelperFunction";
import CustomInput from "../../custom-input-fields/CustomInput";
import Image from "./Image";

function AcceptUserInvite() {
    const navigate = useNavigate();
    const alert = useAlert();
    const params = useParams();
    const { inviteCode, email, company } = params;
    const [userData, setUserData] = useState(null);
    const [pending, setPending] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const acceptInvite = async () => {
        setPending(true)
        let variables = {}
        variables.inviteCode = decryptData(inviteCode);
        variables.email = isStringValue(decryptData(email))
        variables.firstName = isStringValue(userData?.firstName);
        variables.lastName = isStringValue(userData?.lastName);
        variables.contactNumber = isStringValue(userData?.contactNumber);
        variables.password = isStringValue(userData?.password);
        variables.confirmPassword = isStringValue(userData?.confirmPassword);
        variables.zipCode = isStringValue(userData?.zipCode);
        let request = getRequestForApi(global.ACCEPT_INVITE, variables, methodType.POST);
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                let data = getDefaultValues().ALERT_OPTIONS;
                data.passphrase = response?.data?.wallet?.passphrase[0];
                data.showButton = true;
                alert.show(alertMessages.INVITE_ACCEPT_SUCCESS, { type: alertTypes.SUCCESS });
                navigate(path.HOME);
            }
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log("err", err)
        })
    };

    const disableSubmit = () => {
        if (!isBooleanValue(userData?.termsAccepted) || !isBooleanValue(userData?.isZipCodeValid) || isStringValue(userData?.firstName) === "" || isStringValue(userData?.lastName) === "" || isStringValue(userData?.contactNumber) === "" || isStringValue(userData?.password) === "" || isStringValue(userData?.confirmPassword) === "") {
            return true
        } else {
            return false
        };
    };

    return (
        <>
            {pending && <CustomLoader />}
            <form className="accept-invite-padding">
                <div className="form-row">
                    <h1 className="fs-2 fw-bold">Accept Invite for {isStringValue(decryptData(company))}</h1>
                    <p>Please enter your details</p>
                </div>
                <div className="form-row">
                    <label htmlFor="email">Email<span className="required">*</span></label>
                    <div className="input-box d-flex">
                        <Image src={IMAGE_TYPES.USER_LOGIN} alt />
                        <CustomInput
                            fieldType={"email"}
                            setFieldData={setUserData}
                            defaultName={isStringValue(decryptData(email))}
                            fieldId={'#s_email'}
                            keyName={'email'}
                            isReadOnly="true"
                        />
                    </div>
                    <p className='warninig'>{userData?.email && !userData?.isEmailValid ? errorMessages.ENTER_VALID_EMAIL : ''}</p>
                </div>
                <div className="form-row">
                    <label htmlFor="firstName">First Name<span className="required">*</span></label>
                    <div className="input-box d-flex briefcase">
                        <Image src={IMAGE_TYPES.USER_LOGIN} alt />
                        <CustomInput
                            fieldType={"text"}
                            setFieldData={setUserData}
                            filedData={userData}
                            fieldId={'#s_firstName'}
                            keyName={'firstName'}
                            placeholderType={"Enter your first name "}
                            autocomplete="off"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <label htmlFor="lastName">Last Name<span className="required">*</span></label>
                    <div className="input-box d-flex">
                        <Image src={IMAGE_TYPES.USER_LOGIN} alt />
                        <CustomInput
                            fieldType={"text"}
                            setFieldData={setUserData}
                            filedData={userData}
                            fieldId={'#s_email'}
                            keyName={'lastName'}
                            placeholderType={"Enter your last name"}
                            autocomplete="off"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <label htmlFor="contactNumber">Phone Number<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <Image src={IMAGE_TYPES.PHONE_NUMBER} alt />
                        <CustomInput
                            fieldType={"phone"}
                            setFieldData={setUserData}
                            filedData={userData}
                            fieldId={'#s_contactNumber'}
                            keyName={'contactNumber'}
                            placeholderType={"Enter your  Phone Number here"}
                            autocomplete="off"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <label htmlFor="zipCode">Zip Code<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <Image src={IMAGE_TYPES.PHONE_NUMBER} alt />
                        <CustomInput
                            fieldType={"number"}
                            setFieldData={setUserData}
                            filedData={userData}
                            fieldId={'#s_zipCode'}
                            keyName={'zipCode'}
                            placeholderType={"Enter zip code here"}
                            autocomplete="off"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <label htmlFor="password">Password<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <Image src={IMAGE_TYPES.LOCK_PASS} alt />
                        <CustomInput
                            fieldType={showPassword ? "text" : "password"}
                            setFieldData={setUserData}
                            filedData={userData}
                            fieldId={'#si_password'}
                            keyName={'password'}
                            placeholderType={"Enter your password here"}
                            autocomplete="off"
                        />
                        {showPassword ?
                            <i className="bi bi-eye password-icon" onClick={() => setShowPassword(!showPassword)} /> :
                            <i className="bi bi-eye-slash password-icon" onClick={() => setShowPassword(!showPassword)} />}
                    </div>
                    {userData?.password && <p className='warninig'>
                        {!userData['isPasswordValid'] ? errorMessages.PASSWORD : ''}
                    </p>}
                </div>
                <div className="form-row">
                    <label htmlFor="confirmPassword">Confirm Password<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <Image src={IMAGE_TYPES.LOCK_PASS} alt />
                        <CustomInput
                            fieldType={showConfirmPassword ? "text" : "password"}
                            setFieldData={setUserData}
                            filedData={userData}
                            fieldId={'#s_confirmPassword'}
                            keyName={'confirmPassword'}
                            placeholderType={"Enter your confirm password"}
                        />
                        {showConfirmPassword ?
                            <i className="bi bi-eye password-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> :
                            <i className="bi bi-eye-slash password-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />}
                    </div>
                    {userData?.confirmPassword && <p className='warninig'>
                        {!userData['isConfirmPasswordValid'] ? errorMessages.PASSWORD : ''}
                    </p>}
                    <p className="warninig">{userData?.password && userData?.confirmPassword && userData?.password !== userData?.confirmPassword ? 'Password  and confirm password should be same' : ''}</p>
                </div>
                <div className="form-row">
                    <button type="button" className={`btn1 ${disableSubmit() ? 'disable-button' : " "}`} onClick={() => acceptInvite()}>Submit</button>
                </div>
                <div className="signup mt-2">
                    <p>
                        <input type="checkbox" onChange={() => setUserData({ ...userData, termsAccepted: !isBooleanValue(userData?.termsAccepted) })} />&nbsp;
                        I have read and agree to the <a className="text-decoration-none" href="#" onClick={() => openWindow(getHostUrl() + path.PRIVACY_POLICY)}>privacy policy </a>and <a className="text-decoration-none" href="#" onClick={() => openWindow(getHostUrl() + path.TERMS_MOBILE)}>terms and conditions</a>
                    </p>
                </div>
            </form>
        </>
    )
}

export default AcceptUserInvite