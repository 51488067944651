import React, { useEffect, useState } from 'react'
import { encryptData } from '../../utility-files/data-encryption-util/DataEncryption'
import { alertTypes, path } from '../../utility-files/helper-function/HelperFunction'
import * as global from '../../constants/global'
import { isIntegerValue, isStringValue } from '../../utility-files/data-util/DataHandling'
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest'
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest'
import { useAlert } from 'react-alert'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ThemeButton from '../../qed-theme-module/ThemeButton'
import { setStorageData, storageType } from '../../utility-files/storage-util/StorageHandling'

function CheckoutPlan() {
    const alert = useAlert();
    const navigate = useNavigate();
    const [selectPlan, setSelectPlan] = useState(null);
    const [pending, setPending] = useState(false);
    const themeData = useSelector((state) => state.reduxReducer.themeData);

    const divCustomTheme = {
        background: isStringValue(themeData.backgroundColor),
        fontFamily: isStringValue(themeData.fontName),
    };

    const elementStyle = {
        fontFamily: isStringValue(themeData?.fontName),
        color: isStringValue(themeData?.textColor)
    };

    useEffect(() => {
        Checkout();
    }, []);

    const Checkout = async () => {
        let variables = {}
        variables.planId = 0
        variables.planFor = "Manufacturer"
        setPending(true);
        let request = getRequestForApi(global.QED_PAYMENT_PLAN, variables, methodType.POST)
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                let apiData = response?.data?.response?.data;
                setSelectPlan(apiData)
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        })
    };

    const getMoreTokens = async () => {
        setPending(true);
        let request = getRequestForApi(global.GET_MORE_TOKENS, undefined, methodType.POST)
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201 && response?.data?.error === 200) {
                alert.show(response?.data?.response?.data, { type: alertTypes.SUCCESS });
                navigate(path.HOME);
            };
            setPending(false);
        }).catch((err) => {
            console.log('err', err);
            setPending(false);
        })
    };

    const handlePlan = async (plan) => {
        setPending(true);
        let variables = {};
        variables.planId = isIntegerValue(plan?.id);
        variables.noOfTokens = isIntegerValue(plan?.noOfTokens);
        variables.amount = isStringValue(plan?.amount * 100);
        variables.name = plan?.planName;
        variables.description = plan?.description;
        if (window.location.hostname === "localhost") {
            variables.env = "Dev";
        }
        let request = getRequestForApi(global.QED_CREATE_SINGLE_CHECKOUT_SESSION, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data;
                let sec_data = {
                    id: responseData?.id,
                    planId: variables.planId,
                    noOfTokens: variables.noOfTokens
                }
                setStorageData(!storageType, 'sec_id', encryptData(JSON.stringify(sec_data)));
                window.location.replace(responseData?.url);
            };
            setPending(false);
        }).catch((err) => {
            setPending(false);
        });
    };

    return (
        <>
            <div id="generic_price_table" style={divCustomTheme}>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="price-heading clearfix">
                                    <h1 style={elementStyle}>Please select a Plan</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {selectPlan?.map((data, index) => {
                                return <div className="col-md-4 mt-5" key={`tokens_plan_${data?.Id}_${index}`}>
                                    <div className="generic_content clearfix">
                                        <div className="generic_head_price clearfix">
                                            <div className="generic_head_content clearfix">
                                                <div className="head_bg"></div>
                                                <div className="head">
                                                    <span style={elementStyle}>{data?.planName}</span>
                                                </div>
                                            </div>
                                            <div className="generic_price_tag clearfix">
                                                <span className="price">
                                                    <span className="currency" style={elementStyle}>$</span>
                                                    <span className="currency" style={elementStyle}>{isIntegerValue(data?.amount)?.toLocaleString()}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="generic_feature_list">
                                            <ul>
                                                <li style={elementStyle}>{data?.description}</li>
                                            </ul>
                                        </div>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            {/* <Suspense fallback={<CustomLoader />}>
                                                <PaymentPage/>
                                            </Suspense> */}
                                            <ThemeButton type="button" className='btn btn-primary' onClick={() => handlePlan(data)}>Buy Now</ThemeButton>
                                        </div>
                                        <div className="generic_price_btn clearfix">
                                            {/* <Link to={path.PAYMENTS + '/'+ encryptData(JSON.stringify(data))}>Buy Now</Link> */}
                                            {/* <ThemeButton type="button" onClick={() => handlePlan(data)}>Buy Now</ThemeButton> */}
                                            {/* <Payments key={`tokens_plan_${data?.id}_${index}`} plan={data} /> */}
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default CheckoutPlan
